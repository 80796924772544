import moment from "moment";
import PubSub from "pubsub-js";
import { init as initApm } from "@elastic/apm-rum";

export * from "./constansForComponents";
export * from "./services";

export const publishLib = (event, data) => PubSub.publish(event, data);
export const subscribeLib = (event, callback) =>
  PubSub.subscribe(event, callback);

initApm({
  serviceName: process.env.ELASTIC_APM_SERVICE_NAME,
  serverUrl: process.env.ELASTIC_APM_SERVICE_URL,
  environment: process.env.ELASTIC_APM_ENVIRONMENT,
});

class CustomFormatter extends Intl.NumberFormat {
  format(value) {
    const formattedValue = Number(value) ? super.format(value) : 0;
    return `$${formattedValue}`;
  }
}

export const formatter = new CustomFormatter("es-ES", {
  style: "currency",
  currency: "COP",
  maximumFractionDigits: 2,
  useGrouping: true,
});

export const formatterUSD = new CustomFormatter("es-ES", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  currencyDisplay: "code",
  useGrouping: true,
});

export const currenciesSum = (currencies: [], isDollar = false) => {
  let sum = 0;
  currencies.forEach((currency) => {
    sum += parseFloat(currency);
  });

  return isDollar ? formatterUSD.format(sum) : formatter.format(sum);
};

export const currenciesSumTotal = (data) => {
  return data.reduce((acc, { value, currency }) => {
    acc[currency] = (acc[currency] || 0) + value;
    return acc;
  }, {});
};

export const parseDateWihtFormat = (date, format) => {
  return moment(date).format(format);
};

export const parseDateLocaleFormat = (date) => {
  return moment(date).locale("es").format("DD-MMM-YYYY");
};

export const hourFormat = (value, format) => {
  return moment().startOf("day").minute(value).format(format);
};

export const parseDate = (fechaStr) => {
  return moment(fechaStr).format("DD-MMM-YY");
};

export const extractTime = (isoDateString) => {
  return moment(isoDateString).format("hh:mm A");
};

export const convertMinutes = (mins) => {
  if (mins === undefined || mins === null) {
    return "Duration not available";
  }

  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  return `${hours}h ${minutes} m   `;
};

export const parseHour = (hour) => {
  const date = moment();
  date.set({
    hour: hour.split(":")[0],
    minutes: hour.split(":")[1],
    seconds: 0,
  });

  return date.format("YYYY-MM-DDTHH:mm:ss");
};

export const getAge = (date) => {
  const today = moment();
  const birthDate = moment(date);
  const age = today.diff(birthDate, "years");
  return age;
};

export const filterEmpty = (data) => {
  const filtered = Object.keys(data).filter((key) => {
    const value = data[key];

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === "string") {
      return value.trim().length > 0;
    }

    if (typeof value === "number") {
      return value > 0;
    }

    if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
    }

    if (typeof value === "boolean") {
      return true;
    }
  });

  return filtered.length > 0
    ? filtered.reduce((obj, key) => ({ ...obj, [key]: data[key] }), {})
    : null;
};

export const getArrayByName = (data, property) => {
  const values = data
    ?.flatMap((obj) => {
      if (obj[property]) return obj[property];
    })
    .filter((x) => x);

  return values.map((value) => ({
    value,
    label: value,
  }));
};

export const numDays = (initialDate, endDate, isNight = false) => {
  const diffDays = moment(endDate).diff(moment(initialDate), "days");
  const numberDays = !isNight ? diffDays + 1 : diffDays;
  return numberDays > 1
    ? `${numberDays} ${isNight ? "Noches" : "Días"}`
    : `${numberDays} ${isNight ? "Noche" : "Día"}`;
};

export const valuePerNight = (
  value,
  initialDate,
  endDate,
  isPerNight = false
) => {
  const numberDays = moment(endDate).diff(moment(initialDate), "days");
  if (isPerNight) {
    return value / numberDays;
  } else {
    return value * numberDays;
  }
};

export const filterByBeneficiaryType = (data, type, field) => {
  const element = data?.find(({ tipo }) => tipo === type);
  return element ? element[field] : "";
};

export const formatterNumberInput = (value) =>
  !Number.isNaN(parseFloat(value))
    ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    : "$ ";

export const parserNumberInput = (value) => value.replace(/\$\s?|(,*)/g, "");

export const getFormatDate = (date) => moment(date).toDate();

export const isExternalFlow = (permission) =>
  permission.includes("Auxilios Ambulatorios Externos");
