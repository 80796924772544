import { startCase, camelCase } from "lodash";
import {
  fetchLocalCities,
  fetchTokenApi,
  fetchFlight,
  fetchCitiesContains,
  fetchTraveLiquidatorByID,
  fetchTraveLiquidatorUpdate,
  fetchHotelDetail,
  fetchHotel,
  fetchAidHolder,
  fetchAidBeneficiary,
  fetchTraveLiquidator,
  fetchExpensesType,
  fetchTravelExpenses,
  fetchTravelServices,
  fetchCities,
  fetchUnionarticleType,
  fetchEmergencyType,
  fetchApprovers,
  fetchBudgetElement,
  fetchTravelCommissionType,
  fetchTravelType,
  fetchUserSummary,
  fetchAidTravelExpenses,
  fetchAttendance,
  fetchVehicle,
  fetchCountries,
  fetchContinents,
  fetchBus,
  fetchAidBudget,
  fetchAidApprovers,
  fetchAidPENAValidation,
  fetchAidServices,
  fetchTravelRequestApprover,
  fetchTravelStatus,
  fetchPreApprovers,
  fetchCreateProvider,
  fetchSeizureList,
  fetchUpdateCreateSeizure,
  fetchDepartments,
  fetchCitiesByDepartment,
  fetchThirdPerson,
  fetchTravelBudgetThirdParties,
  fetchTravelByID,
  fetchTravelRequestCommissioner,
  fetchTravelRequestManager,
  fetchDisclaimerText,
  fetchFrequentTravelerServices,
  fetchFrequentTraveler,
  fetchDisclaimerByUser,
  fetchTraveLiquidatorPut,
  fetchUserFilterCode,
  fetchApproverActive,
  getLegalization,
  fetchProviderById,
  fetchLegalizationConditionServices,
  fetchUserAdditionalInfoByTravel,
  fetchAccountingReports,
  fetchSeizureInfo,
  fetchTravelRequestPreApprover,
  fetchAllCities,
  fetchTravelHistory,
  fetchTravelHistoryFilter,
  fetchDeleteAttendanceDetail,
  fetchDeleteDetailFlight,
  fetchDeleteDetailHotel,
  fetchDeleteTerrestrialDetail,
  fetchDeleteVehicleDetail,
  fetchFareType,
  fetchUserAdditionalInfoUpdateCreate,
  fetchGetSpecialLandTransport,
  fetchNumberOfTripsByUser,
  fetchTravelTypesList,
  fetchOtherExpensesExternalRejectAid,
  fetchAssistancecause,
  fetchAidreferenceBeneficiary,
  fetchTravelRequestExternal,
  fetchTravelRequestPendingManager,
  fetchPendingTravel,
  fetchValidateTripsByBeneficiary,
  fetchBpoBills,
} from "../mfe-ts-common-ave-utilitaries";

import { navigateToNextRoute } from "@mfe/js-common-ave-uiutils";
import { errorApiStore } from "../stores/errorApi-store";

const handleApiError = (error) => {
  if (
    (error.response && error?.response?.status >= 500) ||
    error.code === "ERR_NETWORK" ||
    error.code === "ERR_NETWORK_CHANGED"
  ) {
    errorApiStore.isError = true;
  } else if (String(error).includes("BrowserAuthError")) {
    errorApiStore.isError = true;
    errorApiStore.requestErrorMessage =
      "Su sesión ha caducado, vuelva a iniciarla oprimiendo el botón de iniciar sesión.";
    errorApiStore.buttonMessage = "Iniciar Sesión";
    localStorage.clear();
    sessionStorage.clear();
  }
};

export const getAccessTokenApi = async (instance, accounts, setData) => {
  try {
    const { accessToken } = await fetchTokenApi(instance, accounts);
    setData(accessToken);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchLocalCitiesData = async (setData, accessToken) => {
  try {
    const { data } = await fetchLocalCities(accessToken);
    setData((prev) => ({
      ...prev,
      cities: data?.map((city, index) => ({
        value: `${city.codIATA}/${city.grupoId}/${city.nombreCiudad}`,
        label: city.nombreCiudad,
        key: index,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCountriesData = async (setData, accessToken) => {
  try {
    const { data } = await fetchCountries(accessToken);
    setData((prev) => ({
      ...prev,
      countriesData: data?.map((item) => ({
        value: item.abreviatura,
        label: startCase(camelCase(item.nombre)),
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchFlightData = async (
  setData,
  setLoading,
  setError,
  accessToken,
  pageNumber,
  travelSelection,
  filter,
  filterCity,
  changeCity,
  travelerData
) => {
  try {
    setLoading(true);
    const response = await fetchFlight(
      accessToken,
      pageNumber,
      travelSelection,
      filter,
      filterCity,
      changeCity,
      travelerData
    );

    const flightListData = response.data.options.map((item) => {
      const validationData =
        travelSelection.TipoProceso === 1 ||
        travelSelection.TipoViajeAlias !== "TVINTERNCL";

      const departureFlight = {
        flightSegments:
          item.fligthDeparture && item.fligthDeparture[0].flightSegment,
      };
      const returnsFlight =
        item.fligthReturn &&
        item.fligthReturn.map((returnItem) => {
          return {
            flightSegments: returnItem.flightSegment.map(
              (flightSegment) => flightSegment
            ),
          };
        });

      return validationData
        ? {
            ...item,
          }
        : {
            ...item,
            departureFlight,
            returnsFlight,
          };
    });

    setData((prev) => ({
      ...prev,
      filterFlights: response.data.filter,
      lowerRate: response.data.tarifaMenor,
      higherRate: response.data.tarifaMayor,
      flightList:
        pageNumber !== 1
          ? [...prev.flightList, ...flightListData]
          : flightListData,
    }));
  } catch (error) {
    handleApiError(error);
    if (error?.response?.status === 404) {
      setError(JSON.parse(error.request.responseText).ExceptionMessage);
    }
    if (error?.response?.status === 409) {
      setData((prev) => ({
        ...prev,
        filterFlights: [],
        lowerRate: 0,
        higherRate: 0,
        flightList: [],
      }));
    }
  } finally {
    setLoading(false);
  }
};

export const fetchCitiesContainsData = async (
  setData,
  accessToken,
  serviceID,
  contains,
  isReturn = false
) => {
  try {
    const { data } = await fetchCitiesContains(
      accessToken,
      serviceID,
      contains
    );
    if (isReturn) {
      setData((prev) => ({
        ...prev,
        destinationCities: data?.map((city, index) => ({
          key: index,
          value: city.codIata,
          label: `${city.city}, ${city.countryCode}`,
        })),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        originCities: data?.map((city, index) => ({
          key: index,
          value: city.codIata,
          label: `${city.city}, ${city.countryCode}`,
        })),
      }));
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchHotelData = async (
  setData,
  setLoading,
  setError,
  accessToken,
  pageNumber,
  travelSelection,
  filter,
  filterCity,
  changeCity,
  travelerData
) => {
  try {
    setLoading(true);
    const { data } = await fetchHotel(
      accessToken,
      pageNumber,
      travelSelection,
      filter,
      filterCity,
      changeCity,
      travelerData
    );
    const filteredHoteles = data.options.filter(
      (hotel) => hotel.nameHotel !== "Prueba"
    );

    setData((prev) => ({
      ...prev,
      filterHotels: data?.filter,
      hotelList:
        pageNumber !== 1
          ? [...prev.hotelList, ...filteredHoteles]
          : filteredHoteles,

      serviceId: data.serviceId,
      higherRate: data.tarifaMayor,
      lowerRate: data.tarifaMenor,
    }));
  } catch (error) {
    handleApiError(error);
    setError(JSON.parse(error.request.responseText).ExceptionMessage);
  } finally {
    setLoading(false);
  }
};

export const fetchHotelDetailData = async (
  setData,
  accessToken,
  optionId,
  setLoading,
  travelerId,
  isAid
) => {
  try {
    setLoading(true);
    const { data } = await fetchHotelDetail(
      accessToken,
      optionId,
      travelerId,
      isAid
    );
    setData((prev) => ({
      ...prev,
      hotelDetail: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const getAidHolderData = async (
  setData,
  tokenApi,
  registerId,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchAidHolder(tokenApi, registerId);
    setData(data);
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const getAidBeneficiariesData = async (
  setData,
  tokenApi,
  registerId,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchAidBeneficiary(tokenApi, registerId);
    setData(data);
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchAidTravelExpensesData = async (setData, accessToken) => {
  try {
    const { data } = await fetchAidTravelExpenses(accessToken);
    setData((prev) => ({
      ...prev,
      travelExpenses: data.map((response) => ({
        id: response.id,
        value: `${response.id}/${response.descripcion}/${response.alias}`,
        label: response.descripcion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchTravelChangeUserData = async (
  setData,
  accessToken,
  contains,
  dataUser,
  setLoading
) => {
  try {
    const IsSindical =
      dataUser.rules.perfilPrincipal === "Gestor Sindical" ||
      dataUser.rules.perfilPrincipal === "Comisionado Sindical"
        ? 2
        : 3;

    setLoading(true);
    const response = await fetchUserFilterCode(accessToken, {
      queryType: IsSindical,
      employeeCode: contains,
    });

    setData((prev) => ({
      ...prev,
      travelChangeUser: response.data.map((response) => ({
        id: response.id,
        nombre: response.nombre,
        codigoEmpleado: response.codigoEmpleado,
      })),
    }));

    if (response.data?.length === 0 && IsSindical === 3) {
      const { data: summaryData } = await fetchUserSummary(
        accessToken,
        contains
      );
      setData((prev) => ({
        ...prev,
        travelChangeUser: [summaryData]?.map((response) => ({
          id: response.id,
          nombre: response.nombre,
          codigoEmpleado: response.codigoEmpleado,
        })),
      }));
    }
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchThirdTravelChangeUserData = async (
  setData,
  accessToken,
  setLoading,
  idUser
) => {
  try {
    setLoading(true);
    const { data } = await fetchThirdPerson(accessToken, idUser);
    setData((prev) => ({
      ...prev,
      travelThirdChangeUser: data?.map((response) => ({
        id: response?.id,
        nombre: response?.nombre,
        codigoEmpleado: response?.identificacion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};
export const fetchUserSummaryData = async (
  setData,
  setLoading,
  accessToken,
  userId,
  setState = (value) => {}
) => {
  try {
    setState(false);
    setLoading((prev) => ({
      ...prev,
      data: true,
    }));
    const response = await fetchUserSummary(accessToken, userId);
    setData((prev) => ({
      ...prev,
      travelUserSummary: response.data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading((prev) => ({
      ...prev,
      data: false,
    }));
    setState(true);
  }
};

export const fetchTravelTypeData = async (
  setData,
  accessToken,
  userData,
  traveler,
  setModalContent,
  open
) => {
  try {
    const response = await fetchTravelType(accessToken, userData, traveler);
    setData((prev) => ({
      ...prev,
      travelType: response.data.map((response) => ({
        value: response.id,
        label: response.name,
      })),
    }));
  } catch (error) {
    if (error?.response?.status === 404) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
      open();
    }
    handleApiError(error);
  }
};

export const fetchTravelCommissionTypeData = async (
  setData,
  accessToken,
  travelType,
  employeetype
) => {
  try {
    const response = await fetchTravelCommissionType(
      accessToken,
      travelType,
      employeetype
    );
    setData((prev) => ({
      ...prev,
      travelCommission: response.data.map((response) => ({
        value: response.id,
        label: response.nombre,
        alias: response.alias,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchBudgetElementsData = async (
  setData,
  accessToken,
  formData,
  setModalContent,
  open,
  setLoadingBudget
) => {
  try {
    setLoadingBudget(true);
    const response = await fetchBudgetElement(accessToken, formData);
    setData((prev) => ({
      ...prev,
      travelBudget: {
        default: response.data.default,
        elements: response.data.elements.map((response) => ({
          value: `${response.Codigo} / ${response.TipoElementoPresupuestal}`,
          label: `${response.Codigo} / ${response.TipoElementoPresupuestal}`,
          description: response.Descripcion,
        })),
      },
    }));
  } catch (error) {
    if (error?.response?.status === 404) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
      open();
    }
    handleApiError(error);
  } finally {
    setLoadingBudget(false);
  }
};

export const fetchThirdBudgetElementsData = async (
  setData,
  accessToken,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelBudgetThirdParties(accessToken);
    setData((prev) => ({
      ...prev,
      travelThirdBudget: data.map((response) => ({
        value: `${response.codigo} / ${response.tipoElementoPresupuestal}`,
        label: `${response.codigo} / ${response.tipoElementoPresupuestal}`,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchApproversData = async (
  setData,
  accessToken,
  travelType,
  employeeCode,
  codPosition,
  typeOfficial,
  setLoading,
  setLoadingApprovers = (value) => {}
) => {
  try {
    setLoading(true);
    setLoadingApprovers(true);
    const { data } = await fetchApprovers(
      accessToken,
      travelType,
      employeeCode,
      codPosition,
      typeOfficial
    );
    setData((prev) => ({
      ...prev,
      travelApprovers: data?.userSimplified?.map((response) => ({
        nombre: response.nombre,
        codigoEmpleado: response.codigoEmpleado,
      })),
      approversDefault: data?.defaultCodes,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
    setLoadingApprovers(false);
  }
};

export const fetchPreApproversData = async (
  setData,
  accessToken,
  travelType,
  employeeCode,
  commisionType,
  codPosition,
  typeOfficial,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchPreApprovers(
      accessToken,
      travelType,
      employeeCode,
      commisionType,
      codPosition,
      typeOfficial
    );
    setData((prev) => ({
      ...prev,
      travelPreApprovers: data?.userSimplified?.map((response) => ({
        nombre: response.nombre,
        codigoEmpleado: response.codigoEmpleado,
      })),
      preApproversDefault: data?.defaultCodes,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchEmergencyTypeData = async (
  setData,
  accessToken,
  travelType
) => {
  try {
    const response = await fetchEmergencyType(accessToken, travelType);
    setData((prev) => ({
      ...prev,
      travelEmergencyType: response.data.map((response) => ({
        value: `${response.id}/${response.descripcion}`,
        label: response.descripcion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchUnionarticleTypeData = async (
  setData,
  accessToken,
  travelType
) => {
  try {
    const response = await fetchUnionarticleType(accessToken, travelType);
    setData((prev) => ({
      ...prev,
      travelUnionarticleType: response.data.map((response) => ({
        value: response.id,
        label: response.descripcion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCitiesData = async (
  setData,
  accessToken,
  travelType,
  date,
  setModalContent,
  open,
  getApproverByID,
  setPolitician = (value) => {},
  isNeededMessage = true
) => {
  try {
    const { data } = await fetchCities(accessToken, travelType, date);
    if (data.mensaje.length > 0 && isNeededMessage) {
      setModalContent({
        content: data.mensaje,
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
      open();
      setPolitician(true);
      getApproverByID();
    }
    setData((prev) => ({
      ...prev,
      travelDestinations: data.resumenCiudades.map((city) => ({
        value: `${city.codIATA}/${city.grupoId}/${city.nombreCiudad}`,
        label: `${city.nombreCiudad}, ${city.abreviacionPais}`,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchTravelServicesData = async (
  setData,
  accessToken,
  commisionerId,
  iataCityDestination,
  form
) => {
  try {
    const response = await fetchTravelServices(
      accessToken,
      commisionerId,
      iataCityDestination,
      form
    );
    setData((prev) => ({
      ...prev,
      travelServices: response.data.map((response) => ({
        id: response.idServicio,
        title: response.nombreServicio,
        alias: response.alias,
        value: response.alias,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchTravelExpensesData = async (
  setData,
  accessToken,
  userData,
  setModalContent,
  open,
  form
) => {
  try {
    const response = await fetchTravelExpenses(accessToken, userData);
    setData((prev) => ({
      ...prev,
      travelExpenses: response.data.map((response) => ({
        id: response.id,
        alias: response.alias,
        title: response.nombre,
        value: response.alias,
      })),
    }));
  } catch (error) {
    handleApiError(error);
    if (error?.response?.status === 400) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
      form.setFieldValue("origin", "");
      form.setFieldValue("destination", "");
      form.setFieldValue("searchOrigin", "");
      form.setFieldValue("searchDestination", "");
      open();
    }
  }
};

export const fetchExpensesTypeData = async (setData, accessToken) => {
  try {
    const response = await fetchExpensesType(accessToken);
    setData((prev) => ({
      ...prev,
      travelExpensesType: response.data.map((response) => ({
        id: response.id,
        value: `${response.id}/${response.descripcion}/${response.alias}`,
        label: response.descripcion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchTraveLiquidatorData = async (
  accessToken,
  setLoading,
  setModalContent,
  travelSelection,
  open,
  selectedData = null
) => {
  try {
    setLoading(true);
    const { data } = await fetchTraveLiquidator(accessToken, travelSelection);
    localStorage.setItem("liquidationID", data.Id);
    await fetchDeleteAttendanceDetail(accessToken, data.Id);
    await fetchDeleteDetailFlight(accessToken, data.Id);
    await fetchDeleteTerrestrialDetail(accessToken, data.Id);
    await fetchDeleteVehicleDetail(accessToken, data.Id);
    await fetchDeleteDetailHotel(accessToken, data.Id);

    const upCreateBeneficiary = () => {
      fetchUserAdditionalInfoUpdateCreate(accessToken, {
        viajeId: data?.Id,
        tipo: "BENEFICIARIO",
        codigoEmpleado: data?.CodigoEmpleadoViajero,
        nombres: selectedData?.userSelected?.nombres,
        apellidos: selectedData?.userSelected?.apellidos,
        tipoDocumento: selectedData?.userSelected?.tipoDoc,
        numeroDocumento: selectedData?.userSelected?.doc,
        fechaNacimiento: selectedData?.userSelected?.fechaNac,
        correoElectronico: selectedData?.userSelected.email,
      });
    };
    const upCreateTitular = () => {
      fetchUserAdditionalInfoUpdateCreate(accessToken, {
        viajeId: data?.Id,
        tipo: "TITULAR",
        codigoEmpleado: data?.CodigoEmpleadoViajero,
        nombres: selectedData?.holder?.nombres,
        apellidos: selectedData?.holder?.apellidos,
        tipoDocumento: selectedData?.holder?.tipoDoc,
        numeroDocumento: selectedData?.holder?.doc,
        correoElectronico: selectedData?.holder?.email,
      });
    };

    const upCreateTraveler = () => {
      fetchUserAdditionalInfoUpdateCreate(accessToken, {
        viajeId: data?.Id,
        tipo: "VIAJERO",
        codigoEmpleado: data?.CodigoEmpleadoViajero,
        nombres: data?.NombreEmpleadoViajero,
        tipoDocumento:
          selectedData?.dataUserSummary?.travelUserSummary?.tipoDocumento ||
          selectedData?.dataUser?.datosUsuario?.tipoDocumento,
        numeroDocumento:
          selectedData?.dataUserSummary?.travelUserSummary?.identificacion ||
          selectedData?.dataUser?.datosUsuario?.identificacion,
        correoElectronico:
          selectedData?.dataUserSummary?.travelUserSummary?.correoElectronico ||
          selectedData?.dataUser?.datosUsuario?.correoElectronico,
      });
    };

    if (
      data?.ViajeTrayecto?.flatMap((item) => item.ServicioBpo)?.length === 0
    ) {
      if (data?.TipoViajeAlias === "TVAMBULATORIOS") {
        if (!data?.ViajeTrayecto?.[0]?.auxilioMedico?.Acompanante) {
          await upCreateBeneficiary();
          await upCreateTitular();
        } else {
          await upCreateBeneficiary();
        }
      } else {
        await upCreateTraveler();
      }
    }
    navigateToNextRoute(data);
  } catch (error) {
    handleApiError(error);
    if (error?.response?.status === 400) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
      open();
    }
  } finally {
    setLoading(false);
  }
};

export const fetchTraveLiquidatorByIDData = async (
  setData,
  accessToken,
  id,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchTraveLiquidatorByID(accessToken, id);
    setData((prev) => ({
      ...prev,
      travelLiquidatorData: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTraveLiquidatorUpdateData = async (
  accessToken,
  travelSelection,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchTraveLiquidatorUpdate(
      accessToken,
      travelSelection
    );
    navigateToNextRoute(data);
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTraveLiquidatorPutData = async (
  accessToken,
  setLoading,
  travelSelection
) => {
  try {
    setLoading(true);
    const { data } = await fetchTraveLiquidatorPut(
      accessToken,
      travelSelection
    );
    await fetchDeleteAttendanceDetail(accessToken, data.Id);
    await fetchDeleteDetailFlight(accessToken, data.Id);
    await fetchDeleteDetailHotel(accessToken, data.Id);
    await fetchDeleteTerrestrialDetail(accessToken, data.Id);
    await fetchDeleteVehicleDetail(accessToken, data.Id);
    navigateToNextRoute(data);
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelByIDData = async (
  accessToken,
  travelId,
  setData,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelByID(accessToken, travelId);
    setData((prev) => ({
      ...prev,
      travelStatus: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchAttendanceData = async (
  accessToken,
  setData,
  setLoading,
  setError,
  payload
) => {
  try {
    setLoading(true);
    const { data } = await fetchAttendance(accessToken, payload);
    setData((prev) => ({
      ...prev,
      attendanceList: data.options,
    }));
  } catch (error) {
    handleApiError(error);
    if (error?.response?.status === 404) {
      setError(true);
    }
  } finally {
    setLoading(false);
  }
};

export const fetchVehicleData = async (
  accessToken,
  setData,
  setLoading,
  setError,
  payload
) => {
  try {
    setLoading(true);
    const { data } = await fetchVehicle(accessToken, payload);
    setData((prev) => ({
      ...prev,
      serviceId: data.serviceId,
      lowerRate: data.lowerRate,
      higherRate: data.higherRate,
      vehicleList: data.options,
      filterList: data.filter,
    }));
  } catch (error) {
    handleApiError(error);
    setError(JSON.parse(error.request.responseText).ExceptionMessage);
  } finally {
    setLoading(false);
  }
};

export const fetchContinentsData = async (accessToken, setData) => {
  try {
    const { data } = await fetchContinents(accessToken);
    setData((prev) => ({
      ...prev,
      continents: data.listCity.map((continent) => ({
        value: `${continent.countryCode}/${continent.country}`,
        label: continent.country,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchBusData = async (
  accessToken,
  setData,
  setError,
  setLoading,
  travelerId
) => {
  try {
    setLoading(true);
    const { data } = await fetchBus(accessToken, travelerId);
    setData((prev) => ({
      ...prev,
      busList: data,
    }));
  } catch (error) {
    handleApiError(error);
    if (error?.response?.status === 409) {
      setError(JSON.parse(error.request.responseText).ExceptionMessage);
    }
  } finally {
    setLoading(false);
  }
};

export const fetchAidBudgetData = async (
  accessToken,
  setData,
  regional,
  city
) => {
  try {
    const { data } = await fetchAidBudget(accessToken, regional, city);
    setData((prev) => ({
      ...prev,
      aidBudget: data.map((budget) => ({
        value: `${budget.codigo}/${budget.tipoElemento}/${budget.codRegional}/${budget.localidad}`,
        label: `${budget.codigo}/${budget.tipoElemento}`,
        description: budget.descripcion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAidApproversData = async (accessToken, setData, payload) => {
  try {
    const { data } = await fetchAidApprovers(accessToken, payload);
    setData((prev) => ({
      ...prev,
      aidApprovers: data.map((approver) => ({
        value: `${approver.codEmpleado}/${approver.nombreApellido}`,
        label: approver.nombreApellido,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAidApproversLeg = async (accessToken, setData, payload) => {
  try {
    const { data } = await fetchAidApprovers(accessToken, payload);
    setData((prev) => ({
      ...prev,
      aidApprovers: data.map((approver) => ({
        nombre: approver.nombreApellido,
        codigoEmpleado: approver.codEmpleado,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAidPENAValidationData = async (
  accessToken,
  setData,
  payload
) => {
  try {
    const { data } = await fetchAidPENAValidation(accessToken, payload);
    setData((prev) => ({
      ...prev,
      aidPENAValidation: data.length > 0 ? data : null,
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAidServicesData = async (accessToken, setData) => {
  try {
    const { data } = await fetchAidServices(accessToken);
    setData((prev) => ({
      ...prev,
      services: data.map((service) => service.servicioViaje),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchTravelRequestApproverData = async (
  accessToken,
  codeEmployee,
  setData,
  pageNumber,
  setLoading,
  filter,
  isNovelty = false
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelRequestApprover(
      accessToken,
      codeEmployee,
      pageNumber,
      filter
    );
    isNovelty
      ? setData((prev) => ({
          ...prev,
          noveltyData: pageNumber !== 1 ? [...prev.noveltyData, ...data] : data,
        }))
      : setData((prev) => ({
          ...prev,
          requestData: pageNumber !== 1 ? [...prev.requestData, ...data] : data,
        }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelRequestPreApproverData = async (
  accessToken,
  codeEmployee,
  setData,
  pageNumber,
  setLoading,
  filter
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelRequestPreApprover(
      accessToken,
      codeEmployee,
      pageNumber,
      filter
    );
    setData((prev) => ({
      ...prev,
      preApproverRequest:
        pageNumber !== 1 ? [...prev.preApproverRequest, ...data] : data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelRequestCommissionerData = async (
  accessToken,
  codeEmployee,
  setData,
  pageNumber,
  setLoading,
  filter
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelRequestCommissioner(
      accessToken,
      codeEmployee,
      pageNumber,
      filter
    );
    setData((prev) => ({
      ...prev,
      requestData: pageNumber !== 1 ? [...prev.requestData, ...data] : data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelRequestManagerData = async (
  accessToken,
  codeEmployee,
  setData,
  pageNumber,
  setLoading,
  filter
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelRequestManager(
      accessToken,
      codeEmployee,
      pageNumber,
      filter
    );
    setData((prev) => ({
      ...prev,
      requestData: pageNumber !== 1 ? [...prev.requestData, ...data] : data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelStatusData = async (
  accessToken,
  setData,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelStatus(accessToken);
    const dataSet = new Set();
    const uniqueData = data.filter((item) => {
      if (dataSet.has(item.alias)) {
        return false;
      } else {
        dataSet.add(item.alias);
        return true;
      }
    });
    const notStatusList = ["ELIMINADO"];
    setData((prev) => ({
      ...prev,
      status: uniqueData
        ?.filter((status) => !notStatusList.includes(status.alias))
        ?.map((status) => ({
          value: status.alias,
          label: status.nombre,
        })),
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchSaveProvider = async (setData, accessToken, payload) => {
  try {
    const { data } = await fetchCreateProvider(accessToken, payload);
    setData(data);
  } catch (error) {
    handleApiError(error);
    setData(false);
  }
};

export const fetchSeizureListData = async (
  accessToken,
  payload,
  setData,
  setError
) => {
  try {
    const { data } = await fetchSeizureList(accessToken, payload);
    setData(data ? data : null);
  } catch (error) {
    handleApiError(error);
    if (error?.response?.status === 404) {
      setError(true);
    }
  }
};

export const fetchUpdateCreateSeizureData = async (
  accessToken,
  payload,
  setLoading
) => {
  try {
    setLoading(true);
    await fetchUpdateCreateSeizure(accessToken, payload);
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchDepartmentsData = async (setData, accessToken) => {
  try {
    const { data } = await fetchDepartments(accessToken);
    setData((prev) => ({
      ...prev,
      departments: data.map((department) => ({
        value: `${department.id}/${department.abreviatura}/${department.nombre}`,
        label: department.nombre,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCitiesByDepartmentData = async (
  setData,
  accessToken,
  payload
) => {
  try {
    const { data } = await fetchCitiesByDepartment(accessToken, payload);
    setData((prev) => ({
      ...prev,
      cities: data.map((city) => ({
        value: `${city.codIATA}/${city.nombreCiudad}/${city.id}`,
        label: city.nombreCiudad,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDisclaimerByUserData = async (
  setData,
  accessToken,
  payload,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchDisclaimerByUser(accessToken, payload);
    setData({
      disclaimerOpc: data?.disclaimerOpc === false ? true : false,
      id: data?.id,
    });
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};
export const fetchDisclaimerTextData = async (
  setData,
  accessToken,
  setLoading,
  name
) => {
  try {
    setLoading(true);
    const { data } = await fetchDisclaimerText(accessToken);
    setData(data.filter((item) => item.nombre === name));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchFrequentTravelerServicesData = async (
  setData,
  accessToken
) => {
  try {
    const { data } = await fetchFrequentTravelerServices(accessToken);
    setData((prev) => ({
      ...prev,
      frequentServices: data.map((service) => ({
        value: service.codigo,
        label: service.descripcion,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchFrequentTravelerData = async (
  setData,
  accessToken,
  payload
) => {
  try {
    const { data } = await fetchFrequentTraveler(accessToken, payload);
    setData((prev) => ({
      ...prev,
      frequentTraveler: data.datosViajeroFrecuente,
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchApproverActiveData = async (
  accessToken,
  setData,
  employeeCode,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchApproverActive(accessToken, employeeCode);
    setData((prev) => ({
      ...prev,
      approverActive: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchLegalizationData = async (
  setData,
  accessToken,
  id,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await getLegalization(accessToken, id);
    setData((prev) => ({
      ...prev,
      legalization: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchProviderByIdData = async (
  setData,
  accessToken,
  id,
  setModalContent,
  open,
  setOpenProviders
) => {
  try {
    const { data } = await fetchProviderById(accessToken, id);
    setData((prev) => ({
      ...prev,
      listOfProviders: data?.map((item) => ({
        value: `${item.id}/${item.numeroDocumento}/${item.nombre}`,
        label: item.nombre,
      })),
    }));
  } catch (error) {
    setOpenProviders((prev) => prev.map((item) => false));
    setModalContent({
      content: JSON.parse(error.request.responseText).ExceptionMessage,
      showButtons: false,
      closeOnClickOutside: true,
      onClick: () => {},
    });
    open();

    handleApiError(error);
  }
};

export const fetchTripLegalizationServicesBpoData = async (
  setData,
  accessToken,
  payload
) => {
  try {
    const { data } = await fetchLegalizationConditionServices(
      accessToken,
      payload
    );
    setData({
      hasStands: data?.data?.soportesCompletosBpo,
      message: data?.data?.detSoportesFaltantes,
    });
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchUserAdditionalInfoByTravelData = async (
  setData,
  accessToken,
  travelID
) => {
  try {
    const { data } = await fetchUserAdditionalInfoByTravel(
      accessToken,
      travelID
    );
    setData((prev) => ({
      ...prev,
      dataAid: data,
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAccountingReportsData = async (setData, accessToken, id) => {
  try {
    const { data } = await fetchAccountingReports(accessToken, id);
    setData((prev) => ({
      ...prev,
      accountingReports: data,
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchSeizureInfoData = async (
  setData,
  accessToken,
  docHolder,
  docBeneficiary
) => {
  try {
    const { data } = await fetchSeizureInfo(
      accessToken,
      docHolder,
      docBeneficiary
    );
    setData((prev) => ({
      ...prev,
      seizureInfo: data,
    }));
  } catch (error) {
    if (error.response.status === 404) {
      setData((prev) => ({
        ...prev,
        seizureInfo: [],
      }));
    }
    handleApiError(error);
  }
};

export const fetchAllCitiesData = async (setData, accessToken, setLoading) => {
  try {
    setLoading(true);
    const { data } = await fetchAllCities(accessToken);
    setData((prev) => ({
      ...prev,
      allCities: data.map((city) => ({
        value: `${city.codigoIata}/${city.nombre}/${city.id}`,
        label: city.nombre,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelHistoryData = async (
  setData,
  setLoading,
  accessToken,
  employeeCode,
  isAid,
  page,
  size
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelHistory(
      accessToken,
      employeeCode,
      isAid,
      page,
      size
    );
    setData((prev) => ({
      ...prev,
      travelHistory: page !== 1 ? [...prev.travelHistory, ...data] : data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelHistoryFilterData = async (
  setData,
  setLoading,
  accessToken,
  employeeCode,
  isAid,
  page,
  size,
  payload
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelHistoryFilter(
      accessToken,
      employeeCode,
      isAid,
      page,
      size,
      payload
    );
    setData((prev) => ({
      ...prev,
      travelHistory: page !== 1 ? [...prev.travelHistory, ...data] : data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchFareTypeData = async (
  setData,
  accessToken,
  userSummary,
  travelTypeId
) => {
  try {
    const { data } = await fetchFareType(
      accessToken,
      userSummary,
      travelTypeId
    );
    setData(data);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchGetSpecialLandTransportData = async (
  setData,
  accessToken,
  id
) => {
  try {
    const { data } = await fetchGetSpecialLandTransport(accessToken, id);
    setData((prev) => ({
      ...prev,
      specialLandTransport: data,
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchNumberOfTripsByUserData = async (
  setData,
  accessToken,
  userId
) => {
  try {
    const { data } = await fetchNumberOfTripsByUser(accessToken, userId);
    setData((prev) => ({
      ...prev,
      numberOfTrips: data,
    }));
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchTravelTypesListData = async (
  setData,
  accessToken,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelTypesList(accessToken);
    setData((prev) => ({
      ...prev,
      travelTypesList: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchOtherExpensesExternalRejectAidData = async (
  setData,
  accessToken,
  setLoading,
  idTravel
) => {
  try {
    setLoading(true);
    const { data } = await fetchOtherExpensesExternalRejectAid(
      accessToken,
      idTravel
    );
    setData((prev) => ({
      ...prev,
      expensesExternalAidData: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchPendingTravelData = async (
  accessToken,
  setData,
  setLoading,
  travelId
) => {
  try {
    setLoading(true);
    const { data } = await fetchPendingTravel(accessToken, travelId);
    setData((prev) => ({
      ...prev,
      expensesExternalAidData: data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchAssistancecauseData = async (
  accessToken,
  setData,
  setLoading
) => {
  try {
    setLoading(true);
    const { data } = await fetchAssistancecause(accessToken);
    setData((prev) => ({
      ...prev,
      assistancecause: data.map((item) => ({
        value: item.Id,
        label: item.NombreCausaAuxilio,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchAidreferenceBeneficiaryData = async (
  accessToken,
  setData,
  setLoading,
  idBeneficiary
) => {
  try {
    setLoading(true);
    const { data } = await fetchAidreferenceBeneficiary(
      accessToken,
      idBeneficiary
    );
    setData((prev) => ({
      ...prev,
      aidreferenceBeneficiary: data.map((item) => ({
        value: item.nroReferencia,
        label: item.nroReferencia,
      })),
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelRequestExternalData = async (
  accessToken,
  codeEmployee,
  setData,
  pageNumber,
  setLoading,
  filterData
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelRequestExternal(
      accessToken,
      codeEmployee,
      pageNumber,
      filterData
    );
    setData((prev) => ({
      ...prev,
      requestData: pageNumber !== 1 ? [...prev.requestData, ...data] : data,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchTravelRequestPendingManagerData = async (
  accessToken,
  codeEmployee,
  setData,
  pageNumber,
  setLoading,
  filterData
) => {
  try {
    setLoading(true);
    const { data } = await fetchTravelRequestPendingManager(
      accessToken,
      codeEmployee,
      pageNumber,
      filterData
    );
    const dataMap = data?.map((item) => ({
      ...item.travel,
      selfManagementStatus: item.selfManagementStatus,
    }));
    setData((prev) => ({
      ...prev,
      requestData:
        pageNumber !== 1 ? [...prev.requestData, ...dataMap] : dataMap,
    }));
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchValidateTripsByBeneficiaryData = async (
  accessToken,
  payload,
  setValidatesBeneficiaryDates
) => {
  try {
    const { data } = await fetchValidateTripsByBeneficiary(
      accessToken,
      payload
    );
    setValidatesBeneficiaryDates({
      state: data?.state,
      message: data?.message,
    });
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchBpoBillsData = async (accessToken, idTravel, setData) => {
  try {
    const { data } = await fetchBpoBills(accessToken, idTravel);
    setData((prev) => ({
      ...prev,
      bpoBills: data,
    }));
  } catch (error) {
    handleApiError(error);
  }
};
