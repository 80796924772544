import { proxy } from "valtio";

interface ErrorApiStore {
  isError: boolean;
  requestErrorMessage: string;
  buttonMessage: string;
}

export const errorApiStore = proxy<ErrorApiStore>({
  isError: false,
  requestErrorMessage:
    "La página a la que está intentando acceder no pudo cargar correctamente, inténtelo de nuevo oprimiendo el botón de reintentar.",
  buttonMessage: "Reintentar",
});
