import { travelExpensesList, servicesList } from "@mfe/js-common-ave-uiutils";
import { parseDateWihtFormat, filterEmpty } from "./index";
import lodash from "lodash";
import moment from "moment";

export const travelInformation = (selectedData, isReturn = false) => {
  return {
    informacionViaje: {
      nombreCiudadOrigen: isReturn
        ? selectedData.destinationCity
        : selectedData.originCity,
      CodCiudadOrigen: isReturn
        ? selectedData.destinationValue
        : selectedData.originValue,
      ciudadOrigenGrupo: isReturn
        ? selectedData.destinationGroup
        : selectedData.originGroup,
      nombreCiudadDestino: isReturn
        ? selectedData.originCity
        : selectedData.destinationCity,
      CodCiudadDestino: isReturn
        ? selectedData.originValue
        : selectedData.destinationValue,
      ciudadDestinoGrupo: isReturn
        ? selectedData.originGroup
        : selectedData.destinationGroup,
      FechaInicio: parseDateWihtFormat(
        selectedData.isMultiTrips
          ? selectedData.dates[0]
          : selectedData.form.values.dates[0],
        "YYYY-MM-DD"
      ),
      FechaFin: parseDateWihtFormat(
        selectedData.isMultiTrips
          ? selectedData.dates[1]
          : selectedData.form.values.dates[1],
        "YYYY-MM-DD"
      ),
    },

    viaticosSeleccionados: !isReturn
      ? selectedData.travelCostsSelected.map((item) => ({
          IdViatico: item.id,
          Alias: item.alias,
        }))
      : [],
    serviciosBpoSeleccionados: !isReturn
      ? selectedData.servicesSelected.map((item) => ({
          bpoId: item.id,
          alias: item.alias,
          valor: 0,
        }))
      : selectedData.servicesSelected
          .filter((item) => item.alias === "AVION")
          .map((item) => ({
            bpoId: item.id,
            alias: item.alias,
            valor: 0,
          })),
  };
};

export const travelInformationUpdate = (
  selectedData,
  isReturn = false,
  isMultiTrip = false,
  index = 0,
  multiTripsSaved = []
) => {
  return {
    Id: isMultiTrip
      ? selectedData?.Id || multiTripsSaved?.[index]?.Id || 0
      : isReturn
      ? selectedData.travelLiquidatorData.ViajeTrayecto[1]?.Id
      : selectedData.travelLiquidatorData.ViajeTrayecto[0]?.Id,
    nombreCiudadOrigen: isReturn
      ? selectedData.destinationCity
      : selectedData.originCity,
    CodigoIataorigen: isReturn
      ? selectedData.destinationValue
      : selectedData.originValue,
    ciudadOrigenGrupo: isReturn
      ? selectedData.destinationGroup
      : selectedData.originGroup,
    nombreCiudadDestino: isReturn
      ? selectedData.originCity
      : selectedData.destinationCity,
    CodigoIatadestino: isReturn
      ? selectedData.originValue
      : selectedData.destinationValue,
    ciudadDestinoGrupo: isReturn
      ? selectedData.originGroup
      : selectedData.destinationGroup,
    FechaInicio: parseDateWihtFormat(
      selectedData.isMultiTrips
        ? selectedData.dates[0]
        : selectedData.form.values.dates[0],
      "YYYY-MM-DD"
    ),
    FechaFin: parseDateWihtFormat(
      selectedData.isMultiTrips
        ? selectedData.dates[1]
        : selectedData.form.values.dates[1],
      "YYYY-MM-DD"
    ),
    ServicioBpo: !isReturn
      ? selectedData.servicesSelected.map((item) => ({
          AliasServicio: item.alias,
          Valor: 0,
        }))
      : selectedData.servicesSelected
          .filter((item) => item.alias === "AVION")
          .map((item) => ({
            AliasServicio: item.alias,
            Valor: 0,
          })),
    Viatico: !isReturn
      ? selectedData.travelCostsSelected.map((item) => ({
          Viaticoid: item.id || item.value,
          AliasViatico: item.alias,
        }))
      : [],
  };
};

export const outpatientcareInformation = (selectedData, isReturn = false) => {
  const validateUser =
    selectedData.beneficiaryData.holder.doc ===
    selectedData.beneficiaryData.userSelected.doc
      ? "TITULAR"
      : "BENEFICIARIO";

  return {
    Auxilio: {
      ...(selectedData.isExternalAid && {
        IdCausaAuxilio: selectedData.form.values.causesAid,
        EsReferenciaSeleccionada: !selectedData.form.values.manualReference,
      }),
      TipoNomina: selectedData.payroll,
      TipoUsuario: selectedData.beneficiaryData.userSelected.tipo1,
      CategoriaUsuario: validateUser,
      PerBeneficiario: {
        fechaInicioViaje: parseDateWihtFormat(
          selectedData.form.values.beneficiaryDates[0],
          "YYYY-MM-DD"
        ),
        fechaFinViaje: parseDateWihtFormat(
          selectedData.form.values.beneficiaryDates[1],
          "YYYY-MM-DD"
        ),
        retorno: selectedData.form.values.isReturnBeneficiary,
        auxilioUtilizado: !selectedData.form.values.aidWithoutBeneficiary,
      },
      PerAcompanante: {
        fechaInicioViaje: parseDateWihtFormat(
          selectedData.form.values.accompanyingDates[0]
            ? selectedData.form.values.accompanyingDates[0]
            : new Date(0),
          "YYYY-MM-DD"
        ),
        fechaFinViaje: parseDateWihtFormat(
          selectedData.form.values.accompanyingDates[1]
            ? selectedData.form.values.accompanyingDates[1]
            : new Date(0),
          "YYYY-MM-DD"
        ),
        retorno: selectedData.form.values.isReturnAccompanying,
        auxilioUtilizado: true,
      },
      observacionDestinoPena: selectedData.form.values.reasonPENA,
      Acompanante: selectedData.form.values.withAccompanying,
      AcompananteTitular: false,
      CalculaAcompanante: selectedData.form.values.accompanyingLiquidate,
      NumeroRef: selectedData.form.values.referenceNumber,
      ObjetoAux: selectedData.form.values.objective,
      CondClinicas: selectedData.form.values.clinicalConditions,
      EsUrgencia: selectedData.form.values.urgency,
    },
    informacionViaje: {
      nombreCiudadOrigen: isReturn
        ? selectedData.destinationCity
        : selectedData.originCity,
      CodCiudadOrigen: isReturn
        ? selectedData.codDestination
        : selectedData.codOrigin,
      ciudadOrigenGrupo: isReturn
        ? selectedData.groupDestinationId
        : selectedData.groupOriginId,
      nombreCiudadDestino: isReturn
        ? selectedData.originCity
        : selectedData.destinationCity,
      CodCiudadDestino: isReturn
        ? selectedData.codOrigin
        : selectedData.codDestination,
      ciudadDestinoGrupo: isReturn
        ? selectedData.groupOriginId
        : selectedData.groupDestinationId,
      FechaInicio:
        selectedData.form.values.urgency &&
        selectedData.form.values.withAccompanying
          ? parseDateWihtFormat(
              selectedData.form.values.accompanyingDates[0],
              "YYYY-MM-DD"
            )
          : parseDateWihtFormat(
              selectedData.form.values.beneficiaryDates[0],
              "YYYY-MM-DD"
            ),
      FechaFin:
        selectedData.form.values.urgency &&
        selectedData.form.values.withAccompanying
          ? parseDateWihtFormat(
              selectedData.form.values.accompanyingDates[1],
              "YYYY-MM-DD"
            )
          : parseDateWihtFormat(
              selectedData.form.values.beneficiaryDates[1],
              "YYYY-MM-DD"
            ),
    },
    serviciosBpoSeleccionados: !isReturn
      ? selectedData.servicesSelected.map((item) => ({
          bpoId: item.id,
          alias: item.alias,
          valor: 0,
        }))
      : selectedData.servicesSelected
          .filter((item) => item.alias === "AVION")
          .map((item) => ({
            bpoId: item.id,
            alias: item.alias,
            valor: 0,
          })),
  };
};

export const travelSelectionDataLiquidator = (selectedData, isAid = false) => {
  const [emergencyID, emergencyDesciption] = (
    selectedData.form.values.travelEmergencyType || ""
  ).split("/");

  const mapData = {
    Proceso: selectedData.process,
    informacionViaje: filterEmpty({
      perfil: selectedData?.fareType?.tipoUsuario,
      categoria: selectedData?.fareType?.idCategoria,
      tipoViajeId: selectedData.form.values.travelType,
      tipoComisionId: selectedData.form.values.travelCommission,
      tipoArticuloSindicalId: selectedData.form.values.travelUnionarticleType,
      tipoEmergenciaId: emergencyID,
      descriptionEmergency: emergencyDesciption,
      termsOfService: selectedData.form.values.termsOfService,
      codigoElementoPresupuestal: isAid
        ? `${selectedData.budgetCode}/${selectedData.budgetElement}`
        : selectedData.form.values.travelBudget,
      aprobadorCodigoEmpleado: isAid
        ? selectedData?.approverCode
        : selectedData?.form?.values?.travelApprover?.codigoEmpleado,
      CodigoEmpleadoViajero: isAid
        ? selectedData.beneficiaryData.userSelected.registro
        : !selectedData?.form?.values?.changeTravelID?.length
        ? selectedData.dataUser.datosUsuario.codigoEmpleado
        : selectedData.form.values.changeTravelID,
      CodigoEmpleadoaprobadorPrevio: isAid
        ? ""
        : selectedData?.form?.values?.travelPreviousApprover?.codigoEmpleado,
      gestorCodigoEmpleado: isAid
        ? selectedData.dataUser.datosUsuario.codigoEmpleado
        : !selectedData?.form?.values?.changeTravelID?.length
        ? selectedData.form.values.changeTravelID
        : selectedData.dataUser.datosUsuario.codigoEmpleado,
      descripcion: isAid
        ? selectedData.form.values.objective
        : selectedData.form.values.travelObjective,
      NombreEmpleadoViajero: isAid
        ? selectedData.beneficiaryData.userSelected.nombresApellidos
        : selectedData.dataUserSummary.travelUserSummary.nombre ||
          selectedData.dataUser.datosUsuario.nombre,
      TieneViaticoPermanente: false,
      Multiviaje: selectedData.isMultiTrips,
      NombreEmpleadoAprobador: isAid
        ? selectedData?.approverName
        : selectedData?.form?.values?.travelApprover?.nombre,
      codigoCargoViajero:
        selectedData?.dataUserSummary?.travelUserSummary?.codCargo ||
        selectedData?.dataUser?.datosUsuario?.codCargo,
    }),
    otrosGastos: selectedData.travelExpensesTypeSelected.map((item) => ({
      IdOtroGastos: item.id,
      Valor: item.value,
      NombOtroGasto: item.name,
      TipoMoneda: isAid ? "cop" : item.currency,
      Alias: item.alias,
    })),
    trayectos: isAid
      ? [
          outpatientcareInformation(selectedData),
          outpatientcareInformation(selectedData, true),
        ]
      : selectedData.isMultiTrips
      ? selectedData.multiTrips.map((item) => travelInformation(item))
      : selectedData.process === 1 || selectedData.form.values.travelType !== 2
      ? [travelInformation(selectedData), travelInformation(selectedData, true)]
      : [travelInformation(selectedData)],
  };
  return mapData;
};

export const travelSelectionDataLiquidatorEdit = (
  selectedData,
  isReturn,
  isAid = false
) => {
  return {
    Id: selectedData.travelLiquidatorData.Id,
    Categoria: selectedData?.form?.values?.thirdParties
      ? selectedData?.dataUser?.datosUsuario?.idCategoria
      : String(selectedData?.fareType?.idCategoria),
    Perfil: selectedData?.fareType?.tipoUsuario,
    TipoViajeId: selectedData.form.values.travelType,
    ComisionId: selectedData.form.values.travelCommission,
    CodigoEmpleadoAprobador:
      selectedData?.form?.values?.travelApprover?.codigoEmpleado,
    Multiviaje: selectedData?.isMultiTrips,
    codigoElementoPresupuestal: isAid
      ? `${selectedData.budgetCode}/${selectedData.budgetElement}`
      : selectedData.form.values.travelBudget,
    TipoProceso: selectedData.process,
    CodigoEmpleadoViajero:
      selectedData?.dataUserSummary?.travelUserSummary?.codigoEmpleado ||
      selectedData?.dataUserSummary?.travelUserSummary?.registroSap,
    CodigoEmpleadoaprobadorPrevio:
      selectedData.form.values.travelPreviousApprover.codigoEmpleado,
    gestorCodigoEmpleado:
      selectedData?.dataUserSummary?.travelUserSummary?.codigoEmpleado ===
      selectedData.dataUser.datosUsuario.codigoEmpleado
        ? ""
        : selectedData.dataUser.datosUsuario.codigoEmpleado,
    descripcion: selectedData.form.values.travelObjective,
    NombreEmpleadoViajero:
      selectedData?.dataUserSummary?.travelUserSummary?.nombre,
    NombreEmpleadoAprobador: selectedData.form.values.travelApprover.nombre,
    TieneViaticoPermanente: false,
    termsOfService: selectedData.form.values.termsOfService,
    codigoCargoViajero: selectedData.dataUserSummary.travelUserSummary.codCargo,
    ViajeGasto: selectedData.travelExpensesTypeSelected.map((item) => {
      return !item.Id
        ? {
            GastoId: item.id,
            Valor: item.value,
            Nombre: item.name,
            TipoMonedaId: isAid ? "cop" : item.currency,
            alias: item.alias,
          }
        : {
            Id: item.Id,
            GastoId: item.id,
            Valor: item.value,
            Nombre: item.name,
            TipoMonedaId: isAid ? "cop" : item.currency,
            alias: item.alias,
          };
    }),
    ViajeTrayecto: selectedData.isMultiTrips
      ? selectedData.multiTrips.map((item, index) =>
          travelInformationUpdate(
            item,
            false,
            true,
            index,
            selectedData.multiTripsSaved
          )
        )
      : selectedData.process === 1 || selectedData.form.values.travelType !== 2
      ? [
          travelInformationUpdate(selectedData),
          travelInformationUpdate(selectedData, true),
        ]
      : [travelInformationUpdate(selectedData)],
  };
};

export const travelSelectionDataLiquidatorEditAid = (selectedData) => {
  const mapData = {
    Id: selectedData?.liquidator?.Id,
    Categoria: selectedData?.dataUser?.datosUsuario?.idCategoria,
    Perfil: selectedData?.dataUser?.datosUsuario?.tipoUsuario,
    TipoViajeId: 8,
    ComisionId: 7,
    CodigoEmpleadoAprobador: selectedData?.approverCode,
    Multiviaje: false,
    codigoElementoPresupuestal: `${selectedData?.budgetCode}/${selectedData?.budgetElement}`,
    TipoProceso: selectedData?.process,
    CodigoEmpleadoViajero: selectedData?.liquidator?.CodigoEmpleadoViajero,
    gestorCodigoEmpleado: selectedData?.liquidator?.gestorCodigoEmpleado,
    descripcion: selectedData?.form?.values?.objective,
    NombreEmpleadoViajero: selectedData?.liquidator?.NombreEmpleadoViajero,
    NombreEmpleadoAprobador: selectedData?.liquidator?.NombreEmpleadoAprobador,
    TieneViaticoPermanente: false,
    termsOfService: true,
    ViajeGasto: selectedData?.travelExpensesTypeSelected?.map((item) => {
      return !item.Id
        ? {
            GastoId: item.id,
            Valor: item.value,
            Nombre: item.name,
            TipoMonedaId: "cop",
            alias: item.alias,
          }
        : {
            Id: item.Id,
            GastoId: item.id,
            Valor: item.value,
            Nombre: item.name,
            TipoMonedaId: "cop",
            alias: item.alias,
          };
    }),
    ViajeTrayecto: [
      outpatientcareInformationUpdate(selectedData),
      outpatientcareInformationUpdate(selectedData, true),
    ],
  };
  return mapData;
};

export const outpatientcareInformationUpdate = (
  selectedData,
  isReturn = false
) => {
  return {
    Id: isReturn
      ? selectedData.liquidator.ViajeTrayecto[1]?.Id
      : selectedData.liquidator.ViajeTrayecto[0]?.Id,
    nombreCiudadOrigen: isReturn
      ? selectedData.destinationCity
      : selectedData.originCity,
    CodigoIataorigen: isReturn
      ? selectedData.codDestination
      : selectedData.codOrigin,
    ciudadOrigenGrupo: isReturn
      ? selectedData.groupDestinationId
      : selectedData.groupOriginId,
    nombreCiudadDestino: isReturn
      ? selectedData.originCity
      : selectedData.destinationCity,
    CodigoIatadestino: isReturn
      ? selectedData.codOrigin
      : selectedData.codDestination,
    ciudadDestinoGrupo: isReturn
      ? selectedData.groupOriginId
      : selectedData.groupDestinationId,
    FechaInicio:
      selectedData.form.values.urgency &&
      selectedData.form.values.withAccompanying
        ? parseDateWihtFormat(
            selectedData.form.values.accompanyingDates[0],
            "YYYY-MM-DD"
          )
        : parseDateWihtFormat(
            selectedData.form.values.beneficiaryDates[0],
            "YYYY-MM-DD"
          ),
    FechaFin:
      selectedData.form.values.urgency &&
      selectedData.form.values.withAccompanying
        ? parseDateWihtFormat(
            selectedData.form.values.accompanyingDates[1],
            "YYYY-MM-DD"
          )
        : parseDateWihtFormat(
            selectedData.form.values.beneficiaryDates[1],
            "YYYY-MM-DD"
          ),

    ServicioBpo: !isReturn
      ? selectedData.servicesSelected.map((item) => ({
          servicioId: item.id,
          aliasServicio: item.alias,
          valor: 0,
        }))
      : selectedData.servicesSelected
          .filter((item) => item.alias === "AVION")
          .map((item) => ({
            servicioId: item.id,
            aliasServicio: item.alias,
            valor: 0,
          })),
    Viatico: [],
    ...(isReturn
      ? {}
      : {
          auxilioMedico: {
            TipoNomina: selectedData.payroll,
            TipoUsuario: selectedData.beneficiaryData.userSelected.tipo1,
            CategoriaUsuario:
              selectedData?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico
                ?.CategoriaUsuario,
            Acompanante: selectedData.form.values.withAccompanying,
            AcompananteTitular:
              selectedData?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico
                ?.AcompananteTitular,
            CalculaAcompanante: selectedData.form.values.accompanyingLiquidate,
            NumeroRef: selectedData.form.values.referenceNumber,
            ObjetoAux: selectedData.form.values.objective,
            CondClinicas: selectedData.form.values.clinicalConditions,
            EsUrgencia: selectedData.form.values.urgency,
            ObservacionDestinoPena: selectedData.form.values.reasonPENA,
            PerBeneficiario: {
              fechaInicioViaje: parseDateWihtFormat(
                selectedData.form.values.beneficiaryDates[0],
                "YYYY-MM-DD"
              ),
              fechaFinViaje: parseDateWihtFormat(
                selectedData.form.values.beneficiaryDates[1],
                "YYYY-MM-DD"
              ),
              retorno: selectedData.form.values.isReturnBeneficiary,
              auxilioUtilizado: !selectedData.form.values.aidWithoutBeneficiary,
            },
            PerAcompanante: {
              fechaInicioViaje: parseDateWihtFormat(
                selectedData.form.values.accompanyingDates[0]
                  ? selectedData.form.values.accompanyingDates[0]
                  : new Date(0),
                "YYYY-MM-DD"
              ),
              fechaFinViaje: parseDateWihtFormat(
                selectedData.form.values.accompanyingDates[1]
                  ? selectedData.form.values.accompanyingDates[1]
                  : new Date(0),
                "YYYY-MM-DD"
              ),
              retorno: selectedData.form.values.isReturnAccompanying,
              auxilioUtilizado: true,
            },
          },
        }),
  };
};

export const selectedCitiesEdit = (data) => {
  return data.map((viaje) => {
    return {
      origin: [
        viaje.CodigoIataorigen,
        viaje.ciudadOrigenGrupo,
        viaje.nombreCiudadOrigen,
      ].join("/"),
      destination: [
        viaje.CodigoIatadestino,
        viaje.ciudadDestinoGrupo,
        viaje.nombreCiudadDestino,
      ].join("/"),
    };
  });
};

export const selectedTravelCostsEdit = (data) => {
  return data
    .flatMap(({ Viatico }) => Viatico)
    .map((item) => ({
      alias: item.AliasValorViatico,
      title: item.AliasViatico,
      value: item.AliasValorViatico,
      id: item.Viaticoid,
    }));
};

export const selectedServicesEdit = (data) => {
  return lodash
    .uniqBy(
      data.flatMap(({ ServicioBpo }) => ServicioBpo),
      "AliasServicio"
    )
    .map((item) => ({
      alias: item.AliasServicio,
      value: item.AliasServicio,
    }));
};

export const selectedTravelExpensesTypeEdit = (data) => {
  return data.map((item) => ({
    id: item.GastoId,
    name: item.Nombre,
    value: item.Valor,
    currency: item.TipoMonedaId,
  }));
};

const selectedOtherExpensesEdit = (data) => {
  return data.map((item) => ({
    Id: item.Id,
    id: item.GastoId,
    name: item.Nombre,
    alias: item.alias,
    value: item.Valor,
    currency: item.TipoMonedaId,
  }));
};

export const travelServicesExpensesData = (
  data,
  defaultData,
  isEdit,
  isLegalization = false
) => {
  if (isEdit && !data?.length) return defaultData;
  return data.map((item) => {
    const iconList = defaultData.find(
      (i) => i.alias === (isLegalization ? item.AliasValorViatico : item.alias)
    );
    return {
      ...item,
      icon: iconList?.icon,
    };
  });
};

const setCommonFormValues = (form, data) => {
  form.setValues({
    travelType: data?.travelLiquidatorData?.TipoViajeId,
    travelCommission: data?.travelLiquidatorData?.ComisionId,
    travelBudget: data?.travelLiquidatorData?.codigoElementoPresupuestal,
    travelApprover: {
      nombre: data?.travelLiquidatorData?.NombreEmpleadoAprobador,
      codigoEmpleado: data?.travelLiquidatorData?.CodigoEmpleadoAprobador,
    },
    travelPreviousApprover: {
      nombre: data?.travelStatus?.funcionarioNombreAprobadorPrevio || "",
      codigoEmpleado: data?.travelStatus?.funcionariosIdAprobadorPrevio || "",
    },
    travelApproverInput:
      data?.travelStatus?.funcionarioNombreAprobadorPrevio?.length > 0
        ? `${
            lodash
              .startCase(
                lodash.camelCase(
                  data?.travelLiquidatorData?.NombreEmpleadoAprobador
                )
              )
              .split(" ")[0]
          } y ${
            lodash
              .startCase(
                lodash.camelCase(
                  data?.travelStatus?.funcionarioNombreAprobadorPrevio
                )
              )
              .split(" ")[0]
          }`
        : `${lodash.startCase(
            lodash.camelCase(
              data?.travelLiquidatorData?.NombreEmpleadoAprobador
            )
          )}`,
    travelEmergencyType: [
      data?.travelLiquidatorData?.tipoEmergenciaId,
      data?.travelLiquidatorData?.descricionEmergencia,
    ].join("/"),
    travelUnionarticleType: data?.travelLiquidatorData?.tipoArticuloSindicalId,
    travelObjective: data?.travelLiquidatorData?.descripcion,
    termsOfService: data?.travelLiquidatorData?.termsOfService,
  });
};

export const editFormDataAid = (form, data) => {
  form.setValues({
    budget: data?.aidBudget?.filter((item) =>
      item.value.startsWith(data?.liquidator?.codigoElementoPresupuestal)
    )?.[0]?.value,
    approver: data?.aidApprovers?.filter(
      (item) => item.label === data?.liquidator?.NombreEmpleadoAprobador
    )?.[0]?.value,
    withAccompanying:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico.Acompanante,
    objective: data?.liquidator?.descripcion,
    referenceNumber:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico.NumeroRef,
    urgency: data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico.EsUrgencia,
    accompanyingDates: [
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante
        ?.fechaInicioViaje?.length > 0
        ? new Date(
            data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante?.fechaInicioViaje
          )
        : null,
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante
        ?.fechaFinViaje.length > 0
        ? new Date(
            data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante?.fechaFinViaje
          )
        : null,
    ],
    beneficiaryDates: [
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
        ?.fechaInicioViaje.length > 0
        ? new Date(
            data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario?.fechaInicioViaje
          )
        : null,
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
        ?.fechaFinViaje.length > 0
        ? new Date(
            data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario?.fechaFinViaje
          )
        : null,
    ],
    isReturnBeneficiary:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
        ?.retorno,
    isReturnAccompanying:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante
        ?.retorno,
    originCity: data?.cities?.filter(
      (item) =>
        item.value.split("/")[0] ===
        data?.liquidator?.ViajeTrayecto?.[0]?.CodigoIataorigen
    )?.[0]?.value,
    destinationCity: data?.cities?.filter(
      (item) =>
        item.value.split("/")[0] ===
        data?.liquidator?.ViajeTrayecto?.[0]?.CodigoIatadestino
    )?.[0]?.value,
    clinicalConditions:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.CondClinicas,
    accompanyingLiquidate:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.CalculaAcompanante,
    aidWithoutBeneficiary:
      !data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
        ?.auxilioUtilizado,
    reasonPENA:
      data?.liquidator?.ViajeTrayecto?.[0]?.auxilioMedico
        ?.ObservacionDestinoPena || "",
  });
};

export const editFormData = (
  form,
  data,
  setData,
  setValue,
  setTravelCostsSelected,
  setServicesSelected,
  setMultiTrips,
  setMultiTripsSaved
) => {
  if (!data?.travelLiquidatorData?.Multiviaje) {
    setCommonFormValues(form, data);

    setServicesSelected(
      selectedServicesEdit(data?.travelLiquidatorData?.ViajeTrayecto)
    );
    setTravelCostsSelected(
      selectedTravelCostsEdit(data?.travelLiquidatorData?.ViajeTrayecto)
    );
    setData((prev) => ({
      ...prev,
      travelExpensesTypeSelected: selectedOtherExpensesEdit(
        data?.travelLiquidatorData?.ViajeGasto
      ),
    }));

    const [firstTrayecto] = data?.travelLiquidatorData?.ViajeTrayecto || [];
    form.setValues((prevFormValues) => ({
      ...prevFormValues,
      dates: [
        moment(firstTrayecto?.FechaInicio).toDate(),
        moment(firstTrayecto?.FechaFin).toDate(),
      ],
      origin: selectedCitiesEdit(data?.travelLiquidatorData?.ViajeTrayecto)[0]
        ?.origin,
      destination: selectedCitiesEdit(
        data?.travelLiquidatorData?.ViajeTrayecto
      )[0]?.destination,
    }));
  } else {
    setCommonFormValues(form, data);

    setValue("multidestino");

    setData((prev) => ({
      ...prev,
      travelExpensesTypeSelected: selectedOtherExpensesEdit(
        data?.travelLiquidatorData?.ViajeGasto
      ),
    }));

    const multiTripsData = data?.travelLiquidatorData?.ViajeTrayecto?.map(
      (item) => ({
        dates: [item.FechaInicio, item.FechaFin],
        servicesExpensesSelected: [
          ...travelServicesExpensesData(
            item.ServicioBpo.map((item) => ({
              alias: item.AliasServicio,
              title: item.AliasServicio,
              value: item.AliasServicio,
            })),
            servicesList,
            false
          ),
          ...travelServicesExpensesData(
            item.Viatico.map((item) => ({
              alias: item.AliasValorViatico,
              title: item.AliasViatico,
              value: item.Viaticoid,
            })),
            travelExpensesList,
            false
          ),
        ],
        originValue: item.CodigoIataorigen,
        originGroup: item.ciudadOrigenGrupo,
        originCity: item.nombreCiudadOrigen,
        destinationValue: item.CodigoIatadestino,
        destinationGroup: item.ciudadDestinoGrupo,
        destinationCity: item.nombreCiudadDestino,
        travelCostsSelected: [
          ...travelServicesExpensesData(
            item.Viatico.map((item) => ({
              alias: item.AliasValorViatico,
              title: item.AliasViatico,
              value: item.Viaticoid,
            })),
            travelExpensesList,
            false
          ),
        ],
        servicesSelected: [
          ...travelServicesExpensesData(
            item.ServicioBpo.map((item) => ({
              alias: item.AliasServicio,
              title: item.AliasServicio,
              value: item.AliasServicio,
            })),
            servicesList,
            false
          ),
        ],
        travelExpensesTypeSelected: data?.travelExpensesTypeSelected,
        isMultiTrips: true,
        Id: item.Id,
      })
    );
    setMultiTripsSaved(multiTripsData);
    setMultiTrips((prev) => [...prev, ...multiTripsData]);
  }
};
