import axios from "axios";
import { images } from "@mfe/js-common-ave-uiutils";
import { scopeUserRead, scopeApi } from "@mfe/react-common-ave-msal";

// export //
export * from "./functions";
export * from "valtio";
export { default as moment } from "moment";
export { default as lodash } from "lodash";
export { default as es } from "dayjs/locale/es";
export { jwtDecode } from "jwt-decode";
export { travelStore, errorApiStore } from "./stores";
import { numDays, parseDateWihtFormat, valuePerNight } from "./functions";

const urlgraph = "https://graph.microsoft.com";
const aveApi = process.env.AVE_API_ENDPOINT;


const headerAuthorization = (tokenAutorization) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenAutorization}`,
    },
  };
};

export const fetchGraph = (accessToken) => {
  return axios.get(
    `${urlgraph}/v1.0/me?$select=userPrincipalName,onPremisesSamAccountName,employeeId,companyName,department,displayName,onPremisesDistinguishedName,jobTitle`,
    headerAuthorization(accessToken)
  );
};

export const fetchGraphPhoto = async (accessToken) => {
  const response = await fetch(`${urlgraph}/v1.0/me/photo/$value`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const responsePhotoBlob = await response.blob();
  return response.ok ? URL.createObjectURL(responsePhotoBlob) : images.avatar;
};

export const fetchGraphPhotoById = async (instance, accounts, email) => {
  await instance.initialize();
  const responseToken = await instance.acquireTokenSilent({
    account: accounts[0],
    scopes: scopeUserRead,
  });

  const response = await fetch(`${urlgraph}/v1.0/users/${email}/photo/$value`, {
    headers: {
      Authorization: `Bearer ${responseToken.accessToken}`,
    },
  });
  const responsePhotoBlob = await response.blob();
  return response.ok ? URL.createObjectURL(responsePhotoBlob) : images.avatar;
};

export const fetchAccessTokenGraph = async (instance, accounts) => {
  await instance.initialize();
  const response = await instance.acquireTokenSilent({
    account: accounts[0],
    scopes: scopeUserRead,
  });
  const [userDataGraph, userPhotoDataGraph] = await Promise.all([
    fetchGraph(response.accessToken),
    fetchGraphPhoto(response.accessToken),
  ]);

  return {
    ...userDataGraph.data,
    photo: userPhotoDataGraph,
  };
};

export const fetchTokenApi = async (instance, accounts) => {
  await instance.initialize();
  const response = await instance.acquireTokenSilent({
    scopes: scopeApi,
    account: accounts[0],
  });
  return response;
};

export const fetchHomologation = async (accessToken, email) => {
  return axios.get(
    `${aveApi}/users/v1/user/homologated/email/${email}`,
    headerAuthorization(accessToken)
  );
};
export const fetchMenu = (accessToken, userId, firstLoad) => {
  return axios.get(
    `${aveApi}/users/v1/menu/user/${userId}/firstLoad/${firstLoad}`,
    headerAuthorization(accessToken)
  );
};

export const fetchMenuExternal = (accessToken, employeeId, userId) => {
  return axios.get(
    `${aveApi}/users/v1/externaluser/verificated?employeeid=${employeeId}&userId=${userId}`,
    headerAuthorization(accessToken)
  );
};

export const fetchUserContains = (accessToken, contains) => {
  return axios.get(
    `${aveApi}/users/v1/contains/user/${contains}`,
    headerAuthorization(accessToken)
  );
};

export const fetchUserSummary = (accessToken, userId) => {
  return axios.get(
    `${aveApi}/users/v1/summary/user/${userId}`,
    headerAuthorization(accessToken)
  );
};

export const fetchUserFilterCode = (accessToken, filrerData) => {
  return axios.post(
    `${aveApi}/users/v1/users/filtercode`,
    filrerData,
    headerAuthorization(accessToken)
  );
};

export const fetchUserAdditionalInfoUpdateCreate = (accessToken, data) => {
  return axios.post(
    `${aveApi}/users/v1/additionalinfo/upcreate`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchUserAdditionalInfoByID = (accessToken, userId) => {
  return axios.get(
    `${aveApi}/users/v1/additionalinfo/getByCode/${userId}`,
    headerAuthorization(accessToken)
  );
};

export const fetchUserAdditionalInfoByTravel = (accessToken, travelID) => {
  return axios.post(
    `${aveApi}/users/v1/additionalinfo/travel/${travelID}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchRules = (accessToken, userData) => {
  return axios.post(
    `${aveApi}/rules/v1/ave-init-decision`,
    {
      principalUser: {
        perfiles: userData.perfiles,
        tipoFuncionario: userData.tipoFuncionario,
      },
    },
    headerAuthorization(accessToken)
  );
};

export const fetchTravelType = (accessToken, userData, traveler) => {
  return axios.post(
    `${aveApi}/parameters/v1/traveltype/traveler`,
    {
      principalUser: {
        perfiles: userData.perfiles,
        tipoFuncionario: userData.tipoFuncionario,
      },
      traveler: {
        perfiles: traveler?.perfiles ?? userData.perfiles,
        tipoFuncionario: traveler?.tipoFuncionario ?? userData.tipoFuncionario,
      },
    },
    headerAuthorization(accessToken)
  );
};

export const fetchTravelCommissionType = (
  accessToken,
  travelType,
  employeetype
) => {
  return axios.get(
    `${aveApi}/parameters/v1/commisiontype/traveltype/${travelType}/employeetype/${employeetype}`,
    headerAuthorization(accessToken)
  );
};

export const fetchBudgetElement = (accessToken, formData) => {
  const {
    travelType,
    centroCosto,
    travelCommission,
    travelBudgetSearch,
    ...rest
  } = formData;

  const requestBody = {
    CodigoElemento:
      formData.thirdParties && formData.changeUser
        ? formData.userData.centroCosto
        : centroCosto,
    IdTipoViaje: travelType,
    ...(travelType !== 6 && { IdTipoComision: travelCommission }),
    FiltroCodigo: travelBudgetSearch,
    ...(formData.thirdParties &&
      formData.changeUser && { IdTercero: String(rest.dataUserSummary.id) }),
  };

  return axios.post(
    `${aveApi}/parameters/v1/budgetelement/traveler`,
    requestBody,
    headerAuthorization(accessToken)
  );
};

export const fetchApprovers = (
  accessToken,
  travelType,
  employeeCode,
  codPosition,
  typeOfficial
) => {
  return axios.get(
    `${aveApi}/parameters/v1/approvalresponsible/list/traveltype/${travelType}/employeeCode/${employeeCode}/codPosition/${codPosition}/typeofficial/${typeOfficial}/1/5000`,
    headerAuthorization(accessToken)
  );
};

export const fetchApproverActive = (accessToken, employeeCode) => {
  return axios.get(
    `${aveApi}/parameters/v1/approvalresponsible/approverinboxvalidation/${employeeCode}`,
    headerAuthorization(accessToken)
  );
};

export const fetchPreApprovers = (
  accessToken,
  travelType,
  employeeCode,
  commisionType,
  codPosition,
  typeOfficial
) => {
  return axios.get(
    `${aveApi}/parameters/v1/preaprovers/list/preapproval/typetravel/employeeCode/${employeeCode}/codPosition/${codPosition}/typeofficial/${typeOfficial}/1/5000?idTypeTravel=${travelType}&idTypeCommision=${commisionType}`,
    headerAuthorization(accessToken)
  );
};

export const fetchEmergencyType = (accessToken, travelType) => {
  return axios.get(
    `${aveApi}/parameters/v1/emergencytype/traveltype/${travelType}`,
    headerAuthorization(accessToken)
  );
};

export const fetchUnionarticleType = (accessToken, travelType) => {
  return axios.get(
    `${aveApi}/parameters/v1/unionarticle/traveltype/${travelType}`,
    headerAuthorization(accessToken)
  );
};

export const fetchExpensesType = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/expenses/actives`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelExpenses = (accessToken, userData) => {
  return axios.post(
    `${aveApi}/parameters/v1/travelExpenses/traveler`,
    {
      tipoViajeId: userData.travelType,
      idTipoComision: userData.travelCommission,
      tipoUsuario: userData.tipoUsuario,
      idCategoria: userData.idCategoria,
      iataCiudadOrigen: userData.originValue,
      iataCiudadDestino: userData.destinationValue,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchCities = (accessToken, travelType, date) => {
  return axios.get(
    `${aveApi}/parameters/v1/municipality/${travelType}/${date}`,
    headerAuthorization(accessToken)
  );
};

export const fetchLocalCities = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/municipality/country/32`,
    headerAuthorization(accessToken)
  );
};

export const fetchCountries = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/country`,
    headerAuthorization(accessToken)
  );
};

export const fetchCitiesContains = (accessToken, serviceID, contains) => {
  return axios.get(
    `${aveApi}/bpo/v1/cities/service/${serviceID}/filter/${contains}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelServices = (
  accessToken,
  commisionerId,
  iataCityDestination,
  form
) => {
  const validate = form.travelType === 6;
  return axios.get(
    `${aveApi}/parameters/v1/destinationservice/commisioner/${commisionerId}/iataCity/${iataCityDestination}/traveltype/${
      form.travelType
    }/commissiontype/${validate ? "0" : form.travelCommission}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTraveLiquidator = (accessToken, travelSelection) => {
  return axios.post(
    `${aveApi}/ave/v1/price`,
    travelSelection,
    headerAuthorization(accessToken)
  );
};

export const fetchTraveLiquidatorByID = (accessToken, id) => {
  return axios.get(
    `${aveApi}/ave/v1/price/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTraveLiquidatorUpdate = (accessToken, travelSelection) => {
  return axios.post(
    `${aveApi}/ave/v1/price/refresh`,
    travelSelection,
    headerAuthorization(accessToken)
  );
};

export const fetchTraveLiquidatorPut = (accessToken, travelSelection) => {
  return axios.put(
    `${aveApi}/ave/v1/price`,
    travelSelection,
    headerAuthorization(accessToken)
  );
};

export const fetchTraveLiquidatorDelete = (
  accessToken,
  travelId,
  employeeCode
) => {
  return axios.delete(
    `${aveApi}/ave/v1/travel/${travelId}/${employeeCode}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelSendtoApproval = (
  accessToken,
  travelId,
  employeeCode
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/sendtoapproval/travel/${travelId}/employee/${employeeCode}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchTravelReservation = (accessToken, travelSelection) => {
  return axios.post(
    `${aveApi}/bpo/v1/reservations`,
    {
      codEmpleado: travelSelection.CodigoEmpleadoViajero,
      idViaje: travelSelection.Id,
      serviciosSeleccionados: travelSelection.services,
      codEmpleadoLogueado: travelSelection.codEmpleadoLogueado,
      justificacion: travelSelection.justification,
      serviciosEspeciales: [],
      novedadadViaje: false,
      idNovedadViaje: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchTravelGetReservationByFlight = (accessToken, idViaje) => {
  return axios.post(
    `${aveApi}/bpo/v1/reservations/getreservationbyflight`,
    {
      idViaje: idViaje,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchTravelByID = (accessToken, travelId) => {
  return axios.get(
    `${aveApi}/ave/v1/travel/${travelId}`,
    headerAuthorization(accessToken)
  );
};

export const fetchFlight = (
  accessToken,
  pageNumber,
  travelSelection,
  filter,
  filterCity,
  changeCity,
  travelerData
) => {
  return axios.post(
    `${aveApi}/bpo/v1/flight`,
    {
      traveler: {
        idViajero: travelerData?.CodigoEmpleadoViajero,
        idPerfil: "",
        roundTrip:
          travelSelection.TipoProceso === 1 ||
          travelSelection.TipoViajeAlias !== "TVINTERNCL"
            ? false
            : true,
      },
      informationFlight: {
        user: "110",
        CodPerfil: "1",
        IdFilial: "ECP",
        originDestination: {
          origin: !changeCity
            ? travelSelection?.CodigoIataorigen
            : filterCity.originFilter.split("/")[0],
          destination: !changeCity
            ? travelSelection?.CodigoIatadestino
            : filterCity.destinationFilter.split("/")[0],
          departureDate: !changeCity
            ? parseDateWihtFormat(travelSelection?.FechaInicio, "YYYY-MM-DD")
            : parseDateWihtFormat(filterCity.datesFilter[0], "YYYY-MM-DD"),
          returnDate: !changeCity
            ? parseDateWihtFormat(travelSelection?.FechaFin, "YYYY-MM-DD")
            : parseDateWihtFormat(filterCity.datesFilter[1], "YYYY-MM-DD"),
        },
        services: [
          {
            serviceId: "1",
            maxResponses: "250",
            ServiceType:
              travelSelection.TipoProceso === 1 ||
              travelSelection.TipoViajeAlias !== "TVINTERNCL"
                ? "OW"
                : "RT",
            PaxNumber: travelerData?.ViajeTrayecto?.[0]?.auxilioMedico
              ?.Acompanante
              ? "2"
              : "1",
          },
        ],
      },
      filter: filter,
      isAid: travelerData?.TipoProceso === 4,
      pageNumber: pageNumber,
      resultsNumber: 10,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchFlightReservationDetail = (
  accessToken,
  { flights, price, rates }
) => {
  const getFlightData = (flight) =>
    flight?.ServicioBpo?.find(({ AliasServicio }) => AliasServicio === "AVION");

  const hasChosenFlight = (flight) => !!getFlightData(flight)?.Valor;

  const flightDescription = (flight) =>
    JSON.parse(getFlightData(flight)?.descripcionServicio);

  const getPayload = (flightIndex, isInternational = false) => {
    const flightData = flightDescription(flights[flightIndex]);
    const payload = {
      idViaje: price.Id,
      estado: true,
      idTrayecto: 0,
      esRoundTrip: flights.length > 1,
      rphDeparture: 0,
      rphReturn: 0,
    };

    if (flightIndex === 0 && !isInternational) {
      payload["vueloIda"] = {
        id: flightData.id,
        fares: flightData.fares,
        fligths:
          flightData.fligths || flightData.departureFlight.flightSegments,
        tarifaMenor: rates[0].lowerRate,
        tarifaMAyor: rates[0].higherRate,
      };
    } else {
      payload["vueloRegreso"] = {
        id: flightData.id,
        fares: flightData.fares,
        fligths: flightData.fligths || flightData.returnsFlight.flightSegments,
        tarifaMenor: rates[0].lowerRate,
        tarifaMAyor: rates[0].higherRate,
      };
    }

    return payload;
  };

  const payload =
    flights.length > 0
      ? price.TipoViajeAlias === "TVINTERNCL"
        ? {
            ...getPayload(0),
            vueloRegreso: getPayload(0, true)["vueloRegreso"],
          }
        : !hasChosenFlight(flights[0])
        ? { ...getPayload(1) }
        : !hasChosenFlight(flights[1])
        ? { ...getPayload(0) }
        : { ...getPayload(0), ...getPayload(1) }
      : {
          idViaje: price.Id,
          vueloIda: {
            id: flights.id,
            fares: flights.fares,
            fligths: flights.fligths,
            tarifaMenor: rates.lowerRate,
            tarifaMAyor: rates.higherRate,
          },
          estado: true,
          idTrayecto: 0,
          esRoundTrip: false,
          rphDeparture: 0,
          rphReturn: 0,
        };
  return axios.post(
    `${aveApi}/bpo/v1/flightdetail`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchHotel = (
  accessToken,
  pageNumber,
  travelSelection,
  filter,
  filterCity,
  changeCity,
  travelerData
) => {
  return axios.post(
    `${aveApi}/bpo/v1/hotel`,
    {
      traveler: {
        idViajero: travelerData?.CodigoEmpleadoViajero,
        idPerfil: "",
      },
      informationGetHotelType: {
        user: "110",
        codPerfil: "0",
        idFilial: "",
        originDestination: {
          origin: !changeCity
            ? travelSelection?.CodigoIatadestino
            : filterCity.destinationFilter.split("/")[0],
          destination: !changeCity
            ? ""
            : filterCity.destinationFilter.split("/")[0],
          departureDate: !changeCity
            ? parseDateWihtFormat(travelSelection?.FechaInicio, "YYYY-MM-DD")
            : parseDateWihtFormat(filterCity.datesFilter[0], "YYYY-MM-DD"),
          returnDate: !changeCity
            ? parseDateWihtFormat(travelSelection?.FechaFin, "YYYY-MM-DD")
            : parseDateWihtFormat(filterCity.datesFilter[1], "YYYY-MM-DD"),
        },
        services: [
          {
            serviceId: "2",
            maxResponses: "200",
            serviceType: "OW",
            paxNumber: travelerData?.ViajeTrayecto?.[0]?.auxilioMedico
              ?.Acompanante
              ? "2"
              : "1",
          },
        ],
      },
      filter: filter,
      isAid: travelerData?.TipoProceso === 4,
      pageNumber: pageNumber,
      resultsNumber: 10,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchHotelDetail = (accessToken, optionId, travelerId, isAid) => {
  return axios.post(
    `${aveApi}/bpo/v1/hoteldetails`,
    {
      traveler: {
        idViajero: travelerId,
        idPerfil: "",
      },
      informationHotel: {
        user: "110",
        codPerfil: "",
        idFilial: "",
        isAid,
        optionId: [optionId],
      },
    },
    headerAuthorization(accessToken)
  );
};

export const fetchHotelReservationDetail = (
  accessToken,
  { hotel, price, travel, higherRate, lowerRate, initialDate, finalDate }
) => {
  return axios.post(
    `${aveApi}/bpo/v1/detail/hotel`,
    {
      optionId: [hotel.id],
      idViaje: price.Id,
      fares: hotel.fares,
      nameHotel: hotel.nameHotel,
      diasHotel: Number(numDays(initialDate, finalDate, true).split(" ")[0]),
      valorNoches: valuePerNight(
        hotel.fares.totalAmount,
        initialDate,
        finalDate,
        true
      ),
      ciudad: travel.CodigoIatadestino,
      fechaInicio: initialDate,
      fechaFin: finalDate,
      tarifaMenor: lowerRate,
      tarifaMayor: higherRate,
      idTrayecto: travel.Id,
      fkIdNovedad: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchVehicle = (
  accessToken,
  {
    userData: {
      datosUsuario: { codigoEmpleado, tipoUsuario, filial },
    },
    destination,
    departureDate,
    returnDate,
    filter,
  }
) => {
  return axios.post(
    `${aveApi}/bpo/v1/vehicle`,
    {
      traveler: {
        idViajero: codigoEmpleado,
        idPerfil: "110",
      },
      informationVehicleType: {
        user: "110",
        codPerfil: "2",
        idFilial: filial,
        originDestination: {
          origin: destination.split("/")[0],
          destination: destination.split("/")[0],
          departureDate,
          returnDate,
        },
        services: [
          {
            serviceId: "4",
            maxResponses: "15",
          },
        ],
      },
      filter,
      pageNumber: 0,
      resultsNumber: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchVehicleReservationDetail = (
  accessToken,
  { vehicle, price, travel, higherRate, lowerRate, initialDate, finalDate }
) => {
  return axios.post(
    `${aveApi}/bpo/v1/vehicle/detail`,
    {
      idViaje: price.Id,
      fechaInicio: initialDate,
      fechaFin: finalDate,
      ciudad: travel.CodigoIatadestino,
      car: vehicle.car,
      codeOperatingCar: vehicle.codeOperatingCar,
      description: vehicle.description,
      fares: vehicle.fares,
      id: vehicle.id,
      image: vehicle.image,
      nameOperatingCar: vehicle.nameOperatingCar,
      transmision: vehicle.car.transmissionType,
      vehicleCategory: vehicle.vehicleCategory,
      tarifaMenor: lowerRate,
      tarifaMayor: higherRate,
      idTrayecto: travel.Id,
      fkIdNovedad: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchAttendance = (
  accessToken,
  {
    origin,
    departureDate,
    returnDate,
    birthDate,
    userData: {
      datosUsuario: { codigoEmpleado, tipoUsuario, filial },
    },
  }
) => {
  return axios.post(
    `${aveApi}/bpo/v1/attendance/`,
    {
      traveler: {
        idViajero: codigoEmpleado,
        idPerfil: "110",
        roundTrip: false,
      },
      informationFlight: {
        user: "110",
        CodPerfil: "1",
        Token: "string",
        IdFilial: filial,
        originDestination: {
          origin,
          departureDate,
          returnDate,
          birthDate,
        },
        services: [
          {
            serviceId: "5",
            maxResponses: "200",
          },
        ],
      },
      pageNumber: 0,
      resultsNumber: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchAttendanceReservationDetail = (
  accessToken,
  { attendance, price, travel, higherRate, lowerRate }
) => {
  return axios.post(
    `${aveApi}/bpo/v1/attendance/detail`,
    {
      idViaje: price.Id,
      fechaInicio: travel.FechaInicio,
      fechaFin: travel.FechaFin,
      id: attendance.id,
      name: attendance.name,
      nameCompany: attendance.nameCompany,
      maxAmount: attendance.maxAmount,
      image: attendance.image,
      fares: attendance.fares,
      description: attendance.description,
      servicios: attendance.servicios,
      tarifaMenor: lowerRate,
      tarifaMayor: higherRate,
      idTrayecto: travel.Id,
      fkIdNovedad: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchBus = (accessToken, travelerId) => {
  return axios.post(
    `${aveApi}/bpo/v1/terrestrial`,
    {
      traveler: {
        idViajero: travelerId,
        idPerfil: "110",
      },
      informationTerrestrial: {
        user: "110",
        codPerfil: "2",
        originDestination: {
          origin: "28",
          destination: "15",
          departureDate: "2024-03-10",
          returnDate: "2024-03-14",
        },
        services: [
          {
            serviceId: "3",
            maxResponses: "200",
            serviceType: "OW",
            PaxNumber: 1,
          },
        ],
      },
      pageNumber: 0,
      resultNumber: 0,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchBusReservationDetail = (accessToken, travelSelection) => {
  return axios.post(
    `${aveApi}/bpo/v1/detailterrestrial`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchAidHolder = (accessToken, { doc, alias, register }) => {
  return axios.get(
    `${aveApi}/users/v1/owner?document=${doc}&alias=${alias}&code=${register}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidBeneficiary = (accessToken, registerId) => {
  return axios.get(
    `${aveApi}/users/v1/beneficiaries/code?code=${registerId}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidTravelExpenses = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/otherexpensesaid/actives`,
    headerAuthorization(accessToken)
  );
};

export const fetchContinents = (accessToken) => {
  return axios.get(
    `${aveApi}/bpo/v1/cities/5`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidBudget = (accessToken, regional, city) => {
  return axios.get(
    `${aveApi}/parameters/v1/cecosaidapprover/filter?regional=&city=`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidApprovers = (
  accessToken,
  { budgetCodRegional, budgetLocality }
) => {
  return axios.get(
    `${aveApi}/parameters/v1/associateregionalapprover/list/regional/${budgetCodRegional}/localidad/${budgetLocality}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidPENAValidation = (
  accessToken,
  { origin, destination }
) => {
  return axios.get(
    `${aveApi}/parameters/v1/penacities/origen/${origin}/destination/${destination}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidServices = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/travelcommission/filter?travelId=8&commisionId=7`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestApprover = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/approver/${codeEmployee}/page/${pageNumber}/size/10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestPreApprover = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/approverprevious/${codeEmployee}/page/${pageNumber}/size/10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestCommissioner = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/commissioner/${codeEmployee}/commissionTypeId/0/page/${pageNumber}/size/10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestManager = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/manager/${codeEmployee}/page/${pageNumber}/size/10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestPreviusApprover = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/approverprevious/${codeEmployee}/page/${pageNumber}/size/10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelStatus = (accessToken) => {
  return axios.get(
    `${aveApi}/ave/v1/travelstatus`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestRejectTrip = (accessToken, requestData) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/rejecttrip`,
    requestData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestCloseTrip = (accessToken, requestData) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/closeTrip`,
    requestData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestApproveTrip = (
  accessToken,
  travelId,
  approverCode
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/approvetrip/${travelId}/approver/${approverCode}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestPreApproveTrip = (
  accessToken,
  travelId,
  preApproverCode
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/approvetrip/${travelId}/preapprover/${preApproverCode}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestApproveTripPreapprover = (
  accessToken,
  travelId,
  preapproverCode
) => {
  return axios.get(
    `${aveApi}/ave/v1/travel/approvetrip/${travelId}/preapprover/${preapproverCode}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestApproveTripMasive = (
  accessToken,
  requestData
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/approvetrip/mass`,
    requestData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestApproveLegMassive = (
  accessToken,
  requestData
) => {
  return axios.put(
    `${aveApi}/novelty/v1/legalization/approve/massive`,
    {
      listaRequest: requestData,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchCreateProvider = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/providers/v1/provider`,
    {
      idTipoDocumento: payload.documentType,
      numeroDocumento: payload.id,
      direccion: payload.address,
      telefono: payload.phone,
      correo: payload.email,
      idMunicipio: payload.city,
      nombre: payload.name,
      segundoNombre: payload.secondName,
      primerApellido: payload.lastFirstName,
      segundoApellido: payload.lastSecondName,
      digitoVerificacion: payload.verificationDigit,
      estado: true,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchSeizureList = (
  accessToken,
  { holderDoc, beneficiaryDoc }
) => {
  return axios.get(
    `${aveApi}/accounting/v1/thirdparties/list/${holderDoc}/${beneficiaryDoc}`,
    headerAuthorization(accessToken)
  );
};

export const fetchDepartments = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/department/country/filter/CO`,
    headerAuthorization(accessToken)
  );
};

export const fetchCitiesByDepartment = (accessToken, { id }) => {
  return axios.get(
    `${aveApi}/parameters/v1/municipality/departament/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchThirdPerson = (accessToken, idUser) => {
  return axios.get(
    `${aveApi}/users/v1/users/thirdtypefilter/${idUser}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelBudgetThirdParties = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/ThirdBudgetElement`,
    headerAuthorization(accessToken)
  );
};

export const fetchUpdateCreateSeizure = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/accounting/v1/thirdparties`,
    {
      nombreTitular: payload.holderName,
      documentoTitular: payload.holderDoc,
      nombreBeneficiario: payload.beneficiaryName,
      documentoBeneficiario: payload.beneficiaryDoc,
      ciudadServMedicos: payload.holderCity,
      nombreDemandante: payload.plaintiffName,
      documentoDemandante: payload.id,
      cuentaBancaria: payload.bankAccount,
      entidadBancaria: payload.bank,
      numRadicadoOficio: payload.filingNumber,
      iniVigenciaOficio: payload.initialDate,
      finVigenciaOficio: payload.finalDate,
      estado: payload.status,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchSpecialLandTransport = (accessToken, list) => {
  return axios.post(
    `${aveApi}/ave/v1/SpecialLandTransport`,
    {
      specialLandTransports: list,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchGetSpecialLandTransport = (accessToken, id) => {
  return axios.get(
    `${aveApi}/ave/v1/SpecialLandTransport/specialtransport/byTravelId/${id}/1/10`,
    headerAuthorization(accessToken)
  );
};

export const fetchDisclaimerByUser = (accessToken, id) => {
  return axios.get(
    `${aveApi}/users/v1/disclaimeruser/user/DISCECP/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchDisclaimerText = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/disclaimerclause`,
    headerAuthorization(accessToken)
  );
};

export const fetchUpdateDisclaimerByUser = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/users/v1/disclaimeruser`,
    {
      id: payload?.id,
      funcionarioIdViajero: payload?.travelerId,
      ipCliente: "",
      fechaDisclaimer: parseDateWihtFormat(new Date(), "YYYY-MM-DD"),
      disclaimerOpc: true,
      nombreClausula: "DISCECP",
      idFilial: payload?.filial,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchFrequentTravelerServices = (accessToken) => {
  return axios.get(
    `${aveApi}/bpo/v1/specialservices/bposervice/VUELOS`,
    headerAuthorization(accessToken)
  );
};

export const fetchFrequentTraveler = (accessToken, id) => {
  return axios.get(
    `${aveApi}/users/v1/additionalinfo/frequenttravelerdata/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchFrequentTravelerUpdateCreate = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/users/v1/frequenttravelerdata/upcreate`,
    {
      codViajero: payload.employeeCode,
      codigoAerolinea: payload.code,
      nombreAerolinea: payload.name,
      numViajeroFrecuente: payload.id,
    },
    headerAuthorization(accessToken)
  );
};

export const fetchCardTravel = (accessToken, fileData) => {
  return axios.post(
    `${aveApi}/novelty/v1/novelty/cardstravels`,
    fileData,
    headerAuthorization(accessToken)
  );
};

export const fetchLegalizationConditionServices = (accessToken, id) => {
  return axios.get(
    `${aveApi}/novelty/v1/LegalizationConditionsService/serviciosbpo/viaje/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTripWithoutLegalization = (accessToken, data) => {
  return axios.post(
    `${aveApi}/novelty/v1/legalization/tripwithoutmodification`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchNoTripWithoutLegalization = (accessToken, data) => {
  return axios.post(
    `${aveApi}/novelty/v1/legalization/notrip`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchPrelegalizatinRegister = (accessToken, data) => {
  return axios.post(
    `${aveApi}/novelty/v1/legalization/prelegalizationregister`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchPrelegalizationMulti = (accessToken, data) => {
  return axios.post(
    `${aveApi}/novelty/v1/legalization/prelegalizationregistermulti`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchPrelegalizatinUnion = (accessToken, data) => {
  return axios.post(
    `${aveApi}/novelty/v1/legalization/prelegalizationregistertradeunion`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchLegalizationAudit = (accessToken, id) => {
  return axios.get(
    `${aveApi}/novelty/v1/legalization/audit/request/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchLegalizationSendApprove = (
  accessToken,
  data,
  idLegalization
) => {
  return axios.put(
    `${aveApi}/novelty/v1/legalization/${idLegalization}/sendapprove`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchLegalizationReject = (accessToken, data, idLegalization) => {
  return axios.put(
    `${aveApi}/novelty/v1/legalization/${idLegalization}/reject`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchLegalizationApprove = (accessToken, data, idLegalization) => {
  return axios.put(
    `${aveApi}/novelty/v1/legalization/${idLegalization}/approve`,
    data,
    headerAuthorization(accessToken)
  );
};

export const fetchLegalizationUnionApprove = (accessToken, data) => {
  return axios.put(
    `${aveApi}/novelty/v1/legalization/sindical/${data.idViaje}/approve`,
    data,
    headerAuthorization(accessToken)
  );
};

export const getLegalization = (accessToken, id) => {
  return axios.get(
    `${aveApi}/novelty/v1/legalization/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchProviderById = (accessToken, id) => {
  return axios.get(
    `${aveApi}/providers/v1/provider/${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAccountingReports = (accessToken, id) => {
  return axios.get(
    `${aveApi}/accounting/v1/requests/expenseconcept?flightId=${id}`,
    headerAuthorization(accessToken)
  );
};

export const fetchSentToApproveNovelty = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/novelty/v1/novelty`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchActiveBeneficiary = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/reports/active/aid/beneficiary`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelHistory = (
  accessToken,
  employeeCode,
  isAid,
  page,
  size
) => {
  return axios.get(
    `${aveApi}/ave/v1/travel/reports/summary/employeecode/${employeeCode}/isaid/${isAid}/page/${page}/size/${size}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelHistoryFilter = (
  accessToken,
  employeeCode,
  isAid,
  page,
  size,
  filter
) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/reports/summary/body/employeecode/${employeeCode}/isaid/${isAid}/page/${page}/size/${size}`,
    filter,
    headerAuthorization(accessToken)
  );
};

export const fetchSeizureInfo = (accessToken, docHolder, docBeneficiary) => {
  return axios.get(
    `${aveApi}/accounting/v1/thirdparties/${docHolder}/${docBeneficiary}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAllCities = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/municipality`,
    headerAuthorization(accessToken)
  );
};

export const fetchDeleteDetailFlight = (accessToken, travelId) => {
  return axios.delete(
    `${aveApi}/bpo/v1/flightdetail/travel/${travelId}/novelty/-1`,
    headerAuthorization(accessToken)
  );
};

export const fetchDeleteDetailHotel = (accessToken, travelId) => {
  return axios.delete(
    `${aveApi}/bpo/v1/hoteldetail/${travelId}/noveltyId`,
    headerAuthorization(accessToken)
  );
};

export const fetchDeleteAttendanceDetail = (accessToken, travelId) => {
  return axios.delete(
    `${aveApi}/bpo/v1/attendance/detail/${travelId}/-1`,
    headerAuthorization(accessToken)
  );
};

export const fetchDeleteTerrestrialDetail = (accessToken, travelId) => {
  return axios.delete(
    `${aveApi}/bpo/v1/terrestrialdetail/travel/${travelId}/novelty/-1`,
    headerAuthorization(accessToken)
  );
};

export const fetchDeleteVehicleDetail = (accessToken, travelId) => {
  return axios.delete(
    `${aveApi}/bpo/v1/vehicledetail/travel/${travelId}/novelty/-1`,
    headerAuthorization(accessToken)
  );
};

export const fetchRetrieveApproval = (accessToken, travelData) => {
  return axios.put(
    `${aveApi}/ave/v1/travel/retrieveapproval`,
    travelData,
    headerAuthorization(accessToken)
  );
};

export const fetchCommissionerNotify = (accessToken, travelData) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/commissioner/notify`,
    travelData,
    headerAuthorization(accessToken)
  );
};

export const fetchPermanentViaticum = (
  accessToken,
  { travelId, codeApprover, viaticum }
) => {
  return axios.put(
    `${aveApi}/ave/v1/travel/UpdatePermanentViaticum/travelId/${travelId}/codeApprover/${codeApprover}/viaticum/${viaticum}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchFareType = (accessToken, userSummary, travelTypeId) => {
  return axios.post(
    `${aveApi}/ave/v1/monitoring/getFareTypeForTripType/travel/${travelTypeId}`,
    userSummary,
    headerAuthorization(accessToken)
  );
};

export const fetchApproveUnions = (
  accessToken,
  { travelId, approverCode, flights, buses }
) => {
  return axios.post(
    `${aveApi}/ave/v1/uniontravelapproval/approveTrip/travel/${travelId}/approver/${approverCode}`,
    { IdViaje: travelId, NumVuelos: flights, NumBuses: buses },
    headerAuthorization(accessToken)
  );
};

export const fetchApproveUnionLegalization = (accessToken, payload, id) => {
  return axios.post(
    `${aveApi}/novelty/v1/legalization/sindical/${id}/approve`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchApproveNovelty = (accessToken, payload) => {
  return axios.put(
    `${aveApi}/novelty/v1/novelty/approver`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchRejectNovelty = (accessToken, payload) => {
  return axios.put(
    `${aveApi}/novelty/v1/novelty/decline`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchPSEVariable = (accessToken) => {
  return axios.get(
    `${aveApi}/ave/v1/paramvariable/variablePSE/PSE`,
    headerAuthorization(accessToken)
  );
};

export const fetchUploadBankSupports = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/ave/v1/loadsupportbench`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchNumberOfTripsByUser = (accessToken, userId) => {
  return axios.post(
    `${aveApi}/ave/v1/travel/commissioner/${userId}/counter`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchTravelTypesList = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/traveltype`,
    headerAuthorization(accessToken)
  );
};

export const fetchOtherExpensesExternalAid = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/novelty/v1/supports/load`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchOtherExpensesExternalRejectAid = (accessToken, idTravel) => {
  return axios.get(
    `${aveApi}/novelty/v1/supports/travel/rejected?TravelId=${idTravel}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAssistancecause = (accessToken) => {
  return axios.get(
    `${aveApi}/parameters/v1/assistancecause/list/0/0/true`,
    headerAuthorization(accessToken)
  );
};

export const fetchAmbulatoryAidExternalAction = (
  accessToken,
  payload,
  action
) => {
  const { aidId, observation } = payload;
  return axios.post(
    `${aveApi}/novelty/v1/statusselfmanagement/${action}aid/aid/${aidId}/observation/${observation}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchAidreferenceBeneficiary = (accessToken, idBeneficiary) => {
  return axios.get(
    `${aveApi}/ave/v1/travel/Aidreference/beneficiary?idBeneficiary=${idBeneficiary}`,
    headerAuthorization(accessToken)
  );
};

export const fetchAidreferenceBeneficiaryByID = (
  accessToken,
  idReferenceSelected
) => {
  return axios.get(
    `${aveApi}/ave/v1/travel/Aidreference?reference=${idReferenceSelected}`,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestExternal = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/novelty/v1/supports/travel/pending?codeEmployee=${codeEmployee}&commissionTypeId=1&page=${pageNumber}&size=10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchTravelRequestPendingManager = (
  accessToken,
  codeEmployee,
  pageNumber,
  filterData
) => {
  return axios.post(
    `${aveApi}/novelty/v1/supports/travel/pendingEvaluate?codeEmployee=${codeEmployee}&commissionTypeId=1&page=${pageNumber}&size=10`,
    filterData,
    headerAuthorization(accessToken)
  );
};

export const fetchPendingTravel = (accessToken, travelId) => {
  return axios.get(
    `${aveApi}/novelty/v1/supports/travel/Pending?TravelId=${travelId}`,
    headerAuthorization(accessToken)
  );
};

export const fetchValidateTripsByBeneficiary = (accessToken, payload) => {
  return axios.post(
    `${aveApi}/ave/v1/assist/validateauxiliaryrules`,
    payload,
    headerAuthorization(accessToken)
  );
};

export const fetchChangeProfileManager = (accessToken, employeeCode) => {
  return axios.get(
    `${aveApi}/users/v1/changeprofile/verified/user/${employeeCode}`,
    headerAuthorization(accessToken)
  );
};

export const fetchUpdateProfileManager = (
  accessToken,
  employeeCode,
  managerNumber
) => {
  return axios.put(
    `${aveApi}/users/v1/changeprofile?employeeCode=${employeeCode}&status=${managerNumber}`,
    {},
    headerAuthorization(accessToken)
  );
};

export const fetchBpoBills = (accessToken, idTravel) => {
  return axios.get(
    `${aveApi}/novelty/v1/novelty/consultnoveltiestolegalize?idTravel=${idTravel}&segmentoFin=false`,
    headerAuthorization(accessToken)
  );
};
