import { proxy } from "valtio";

interface TravelStore {
  data: {
    travelType: string;
    travelCommission: string;
    travelCenterCost: string;
    travelObjective: string;
    approver: string;
    travelDates: Date[];
    cityOrigin: string;
    cityDestination: string;
    services: string[];
    costs: string[];
    otherCosts: string[];
  };
  updateData: (prop: keyof TravelStore["data"], newValue) => void;
}

export const travelStore = proxy<TravelStore>({
  data: {
    travelType: "",
    travelCommission: "",
    travelCenterCost: "",
    travelObjective: "",
    approver: "",
    travelDates: [],
    cityOrigin: "",
    cityDestination: "",
    services: [],
    costs: [],
    otherCosts: [],
  },
  updateData: (prop, newValue) => {
    travelStore.data[prop] = newValue;
  },
});
